import React, { useEffect, useState } from "react";
import book from "../src/images/book.png";
import CountdownOverlay from "./CountDownOverlay";
import "./chatform.css";
import KisStory from "./images/create-story.png";
import { AZURE_SUPPORTED_LANGUAGES } from "./languages";
const ChatForm = ({
  handleSubmitWithLoading,
  Age,
  setAge,
  Topic,
  setTopic,
  Characters,
  setCharacters,
  loading,
  language,
  setLanguage = () => { },
  moralOfStory,
  setMoralOfStory,
  //uncomment this code for poly/azure switch feature
  // handleAudioOptionChange,
  // audioOption
}) => {
  const [errors, setErrors] = useState({ Age: false, Topic: false, Characters: false });
  const [clicked, setClicked] = useState(false);

  const handleCreateStoryClick = (e) => {
    e.preventDefault();
    setClicked(true);

    if (Age && Topic && Characters) handleSubmitWithLoading(e)
    else setErrors({ Age: !Boolean(Age), Topic: !Boolean(Topic), Characters: !Boolean(Characters) })
  };


  useEffect(() => {
    if (clicked) setErrors({ Age: !Boolean(Age), Topic: !Boolean(Topic), Characters: !Boolean(Characters) })
  }, [Age, Topic, Characters])

  return (
    <div>
      <div className="form-logo-section">
        <img src={KisStory} alt="story-book" />
      </div>
      <p className="formHeading">Let's start by customizing your story</p>
      <form className="myform" onSubmit={handleCreateStoryClick}>
        <div className="formrow">
          <p className="formLabel formLabel-end" id="age">Age</p>
          <input
            className={`forminput forminput-short ${errors.Age ? 'form-field-error' : ""}`}
            type="number"
            value={Age}
            onChange={(e) => setAge(e.target.value)}
            name="Age"
            min="2"
          />
        </div>
        <div className="formrow">
          <p className="formLabel formLabel-end">Story Topic</p>
          <input
            className={`forminput forminput-long ${errors.Topic ? "form-field-error" : ""}`}
            type="text"
            name="Topic"
            value={Topic}
            onChange={(e) => setTopic(e.target.value)}
            placeholder="e.g, basketball, space, travel"
          />
        </div>
        <div className="formrow">
          <p className="formLabel formLabel-end">
            Moral of the story <br />
            <span className="formlabelsmall">(optional)</span>
          </p>
          <input
            value={moralOfStory}
            onChange={e => setMoralOfStory(e.target.value)}
            className="forminput forminput-long"
            type="text"
            placeholder="e.g always tell the truth"
          />
        </div>
        <div className="formrowBottom">
          <div className="formrow">
            <p className="formLabel formLabel-end character-name">
              Character Names
            </p>
            <input
              className={`forminput forminput-medium ${errors.Characters ? 'form-field-error' : ""}`}
              type="text"
              value={Characters}
              onChange={(e) => setCharacters(e.target.value)}
              name="Characters"
              placeholder="e.g ben and paul"
            />
          </div>
          <div className="formrow">
            <p className="formLabel formLabel-end language-label">Language</p>
            <select id="langiage-dropdown" onChange={e => setLanguage(e.target.value)} style={{ color: "black" }}>
              <option value="" disabled >
                Select a language
              </option>
              {Object.values(AZURE_SUPPORTED_LANGUAGES).map(({ language: _language, flag }, index) =>
                <option value={_language} key={index} selected={_language === language}>{flag}&emsp;{_language}</option>)}
            </select>
          </div>
        </div>
        {/* uncomment this code for poly/azure switch feature */}
        {/* <div className="formrow">
          <p className="formLabel formLabel-end language-label">Audio Toggle</p>
          <input type="radio" id="poly" name="audio_toggle" value="Poly" checked={audioOption === "Poly"} onChange={handleAudioOptionChange}/>
          <label for="poly">Poly</label>
          <input type="radio" id="azure" name="audio_toggle" value="Azure" checked={audioOption === "Azure"} onChange={handleAudioOptionChange}/>
          <label for="azure">Azure</label>
        </div> */}
        <div className="submitbutton">
          <button type="submit">
            {loading ? (
              "Generating Story ..."
            ) : (
              <>
                <img src={book} width="45" height="35" alt="book" />
                <span>Generate Story</span>
              </>
            )}
          </button>
        </div>
        {
          loading && (
            <CountdownOverlay />
          )
        }
      </form >
    </div >
  );
};

export default ChatForm;
