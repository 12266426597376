import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Auth } from "aws-amplify";
import React, { memo, useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import book from "../src/images/book.png";
import ChatForm from "./ChatForm";
import StoryPage from "./StoryPage";
import "./chatbox.css";
import config from "./config.json";
import ArtWork from "./images/Artwork.png";
import chevronLeft from "./images/chevronLeft.png";
import chevronRight from "./images/chevronRight.png";
import KisStory from "./images/create-story.png";

const Page = React.forwardRef((props, ref) => {
    return (
        <div ref={ref} style={{ display: "flex", flexDirection: "column" }}>
            {props.children}
        </div>
    );
});

const subscriptionOptions = [
    { name: "basic", billing_frequency: "monthly", label: "Basic - 20 stories a month - $4.99 monthly", story_limit: 20, price: 4.99, price_id: "price_1N6OBzIcbvaTiSAsUZY6cfCL" },
    { name: "premium", billing_frequency: "monthly", label: "Premium - 40 stories a month - $6.99 monthly", story_limit: 40, price: 6.99, price_id: "price_1N6ODGIcbvaTiSAskm5TEYu1" },
    { name: "gold", billing_frequency: "monthly", label: "Gold - 60 stories a month - $9.99 monthly", story_limit: 60, price: 9.99, price_id: "price_1N6OEzIcbvaTiSAsS1NScDGw" },
    { name: "basic", billing_frequency: "annual", label: "Basic - 240 stories a year - $46.99 annual", story_limit: 240, price: 46.99, price_id: "price_1N3tYmIcbvaTiSAsbHXQbKDD" },
    { name: "premium", billing_frequency: "annual", label: "Premium - 480 stories a year - $66.99 annual", story_limit: 480, price: 66.99, price_id: "price_1N3tYmIcbvaTiSAseX6OADmQ" },
    { name: "gold", billing_frequency: "annual", label: "Gold - 720 stories a year - $94.99 annual", story_limit: 720, price: 94.99, price_id: "price_1N3tYmIcbvaTiSAsovhkK66y" },
];

const images = [
    "https://example.com/image1.jpg",
    "https://example.com/image2.jpg",
    "https://example.com/image3.jpg",
    // Add more image URLs here
];

const PrevArrowButton = ({ onClick }) => (
    <div className="slick-prev" onClick={onClick} style={{ backgroundColor: "rgba(0,0,0,0.5)", width: "50px", height: "80px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <img src={chevronLeft} alt="left" width="35px" height="35px" />
    </div>
);

const NextArrowButton = ({ onClick }) => (
    <div className="slick-next" onClick={onClick} style={{ backgroundColor: "rgba(0,0,0,0.5)", width: "50px", height: "80px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <img src={chevronRight} alt="right" width="35px" height="35px" />
    </div>
);

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    nextArrow: <NextArrowButton />,
    prevArrow: <PrevArrowButton />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 0,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const awsconfig = {
    Auth: {
        region: "us-east-2",
        userPoolId: "us-east-2_3uYXEhMon",
        userPoolWebClientId: "1g6jutokcivrebiu2tf56fpj11",
    },
    credentials: {
        accessKeyId: "AKIARUIO25LGPG655F7N",
        secretAccessKey: "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",
    },
};

// Primary Chat Window
const ChatBox = ({
    isFormSubmitted,
    setIsFormSubmitted,
    slides,
    chatLog,
    loading,
    setLoading,
    Audios,
    setPauseText,
    PauseText,
    playButtonClass,
    setPlayButtonClass,
    handleSubmit,
    setAge,
    Age,
    setTopic,
    Topic,
    setCharacters,
    Characters,
    handleResumeClick,
    handlePauseClick,
    // generateImage,
    voicesList,
    setSelectedVoice,
    email,
    setEmail,
    userData,
    setUserData,
    audioGenerated,
    language,
    setLanguage,
    moralOfStory,
    setMoralOfStory,
    images,
    setErrored,
    setImages,
    setAudios,
    isLanguageSupported,
    voiceId,
    //uncomment this code for poly/azure switch feature
    // handleAudioOptionChange,
    // audioOption
}) => {
    // const [playButtonClass, setPlayButtonClass] = useState([]);
    // const [PauseText, setPauseText] = useState([]);
    // const[loading, setLoading] = useState(false);
    const textDiv = useRef(null);
    const [transId, setTransId] = useState(uuidv4());
    const [likeFeedbackId, setLikeFeedbackId] = useState("");
    const [displayFlag, setDisplayFlag] = useState(false);
    const [popup, setPopup] = useState(false);
    const [reason, setReason] = useState("");
    const [thanksForFeedback, setThanksForFeedback] = useState(false);
    const [thumbsUpClicked, setThumbsUpClicked] = useState(false);
    const likeButtonColor = thumbsUpClicked ? "#ff643d" : "white";
    // const [storyPage, setStoryPage] = useState(false);
    const [testSpinner, setTestSpinner] = useState(false);
    const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
    const flipBookRef = useRef(null);
    const [flipBook, setFlipBook] = useState(null);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);

    Auth.configure(awsconfig);

    const navigate = useNavigate();

    var feedid = "";

    //console.log(email);

    const getUserProfileAddress = "https://cidmp4byjeuct4uqk7lvhgbnja0gdhdc.lambda-url.us-east-2.on.aws/";
    const addUserProfileAddress = "https://wx7dlc2wezs7g47myix66g3k3m0wymxf.lambda-url.us-east-2.on.aws/";
    const updateUserFeedbackAddress = "https://lelzbttutuktkmq7gvmaskdyiq0kkeoj.lambda-url.us-east-2.on.aws/";

    async function getUserProfile(email) {
        const response = await fetch(getUserProfileAddress, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
            }),
        });
        return response;
    }

    const onInit = () => {
        if (flipBookRef?.current) {
            const fb = flipBookRef.current.pageFlip();
            setFlipBook(fb);
        }
    };
    /******* Slider Logic ***/

    // const chatLogText = useMemo(() => chatLog[0]?.message ?? "", [chatLog]);
    // const slides = useMemo(() => {
    //     if (chatLogText) return splitDivIntoSlides(chatLogText, 500);
    //     else return [];
    // }, [chatLogText]);

    const setSlidePauseText = (index, text) => {
        const pauseTexts = [...PauseText];
        pauseTexts[index] = text;
        setPauseText(pauseTexts);
    };

    const setSlidePlayButtonClass = (index, text) => {
        const playButtonClasses = [...playButtonClass];
        playButtonClasses[index] = text;
        setPlayButtonClass(playButtonClasses);
    };

    const handleOnFlip = (e) => {
        const currentIndex = parseInt(currentPageIndex / 2);
        const audio = document.getElementById(`myAudio-${currentIndex}`);
        if (audio) audio.pause();
        setSlidePauseText(currentIndex, "resume");
        setSlidePlayButtonClass(currentIndex, "");
        setCurrentStoryIndex(e.data);
        setCurrentPageIndex(e.data);
    };

    const slickHandleOnFlip = (index) => {
        const currentIndex = parseInt(index - 1);
        const audio = document.getElementById(`myAudio-${currentIndex}`);
        if (audio) audio.pause();
        setSlidePauseText(currentIndex, "resume");
        setSlidePlayButtonClass(currentIndex, "");
    };

    const goToNextPage = () => {
        const _flipBook = flipBook || flipBookRef?.current?.pageFlip();
        if (_flipBook) {
            const currentPage = _flipBook.getCurrentPageIndex();
            setCurrentPageIndex(currentPage);
            const totalPages = _flipBook.getPageCount();
            if (currentPage < totalPages - 2) {
                _flipBook.flipNext();
                return true;
            }
        }
    };

    const flipToNextSlide = () => {
        if (config.autoPlayStory) {
            const isFlipped = goToNextPage();
            setTimeout(() => {
                if (isFlipped) {
                    setCurrentStoryIndex((prevIndex) => {
                        const audio = document.getElementById(`myAudio-${parseInt(prevIndex / 2)}`);
                        if (audio) audio.play();
                        setSlidePauseText(parseInt(prevIndex / 2), "pause");
                        setSlidePlayButtonClass(parseInt(prevIndex / 2), "");
                        return prevIndex;
                    });
                }
            }, 1000);
        }
    };

    const goToPreviousPage = () => {
        if (flipBook) {
            const currentPage = flipBook.getCurrentPageIndex();
            setCurrentPageIndex(currentPage);
            flipBook.flipPrev();
        }
    };

    useEffect(() => {
        async function getCurrentUser() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                console.log("Logged in user email is: " + user.attributes.email);
                setEmail(user.attributes.email);

                const userProfile = await getUserProfile(user.attributes.email).catch((error) => {
                    console.error("Error getting user profile to DB:", error);
                });
                const userProfileData = await userProfile.json();
                await setUserData(userProfileData);
            } catch (error) {
                console.log("error getting current user from Cognito, let's check google " + error);
                //check if user authenticated thru google
                const googleEmail = localStorage.getItem("kidstoryta_email");
                if (googleEmail) {
                    setEmail(googleEmail);
                    const userProfile = await getUserProfile(googleEmail).catch((error) => {
                        console.error("Error getting user profile to DB:", error);
                    });

                    const userProfileData = await userProfile.json();
                    await setUserData(userProfileData);
                }
                // if user is not authenticated that means he accessed this from homepage so let him user it for free
                //navigate('/Home');
            }
        }
        getCurrentUser();
    }, []);

    const checkUsage = async (e) => {
        console.log("usage is: " + userData.story_balance);

        if (!userData.story_balance) {
            console.log("User is not authenticated, let him try the app if he has enough stories left");

            // Get the current count from local storage
            let currentCount = localStorage.getItem("storyCount");

            // If there is no count yet, set it to 0
            if (!currentCount) {
                currentCount = 0;
            } else {
                // If there is a count, parse it as an integer
                currentCount = parseInt(currentCount);
            }

            console.log("currentCount " + currentCount);

            // If the user has already created 5 stories, don't let them create more
            if (currentCount >= 5) {
                //alert("You reached your limit of free stories, join and enjoy 10 more free stories!");
                Swal.fire({
                    title: '<h3 style="font-size: 20px;">Limit Reached</h3>',
                    html: '<p style="font-size: 16px;">You reached your limit. Join to enjoy 5 more free stories!</p>',
                    icon: "warning",
                    confirmButtonText: "Join for free",
                    width: "300px",
                }).then((result) => {
                    // If the user clicked 'Register Now', redirect them to the registration page
                    if (result.isConfirmed) {
                        navigate("/register");
                    }
                });
                return true;
            } else {
                // If they haven't, increment the count and store it back in local storage
                localStorage.setItem("storyCount", currentCount + 1);
                return false;
            }
        } else if (userData.story_balance <= 0) {
            console.log("usage exceeded story limit for the month");
            //alert("You exceeded your story limit for the month, consider upgrading your plan on the profile page");
            Swal.fire({
                title: '<h3 style="font-size: 20px;">Limit Reached</h3>',
                html: '<p style="font-size: 16px;">You reached your limit, upgrade to enjoy more stories!</p>',
                icon: "warning",
                confirmButtonText: "Upgrade",

                width: "300px",
            }).then((result) => {
                // If the user clicked 'Register Now', redirect them to the registration page
                if (result.isConfirmed) {
                    navigate("/plans");
                }
            });
            return true;
        } else {
            console.log("usage is still within the limit");
            return false;
        }
    };

    const updateProfile = async (e) => {
        const response = await fetch(addUserProfileAddress, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userData),
        });
    };

    const handleSubmitWithLoading = async (event) => {
        try {
            event.preventDefault();
            const storyLimitFlag = await checkUsage();

            if (storyLimitFlag === true) {
                //alert("You exceeded your story limit for the month, consider upgrading your plan on the profile page");
                return;
            }
            setLoading(true);
            setDisplayFlag(true);
            localStorage.setItem("chatboxState", "storyPage");
            // setPauseText("pause");
            setTransId(uuidv4());
            // var audio = document.getElementById("myAudio");
            // audio.src =
            //     "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
            // audio.play();
            await handleSubmit(
                transId,
                function (message) {
                    // generateImage(message, transId);
                },
                function (message) {
                    // if (VolumeText === "on") playAudio();
                    console.log("inside callback2");
                }
            );
            const newUserData = userData;
            newUserData.story_balance = parseInt(newUserData.story_balance) - 1;
            setUserData(newUserData);
            await updateProfile();
            // setTimeout(async () => await generateAudio(), 100);
            setAge("");
            setTopic("");
            setCharacters("");
        } catch (error) {
            console.log(error);
            setErrored(true);
        }
    };

    const handleCreateStory = () => {
        const currentIndex = parseInt(currentPageIndex / 2);
        const audio = document.getElementById(`myAudio-${currentIndex}`);
        if (audio) audio.pause();
        localStorage.removeItem("chatboxState");
        // setStoryPage(false)
        setTimeout(() => {
            setImages([]);
            setAudios([]);
            setIsFormSubmitted(false);
        }, 100);
    };

    const handleVoiceChange = (event) => {
        setSelectedVoice(event.target.value);
    };

    const handleafterChange = (currentSlide, nextSlide) => {
        setCurrentStoryIndex(currentSlide);
    };

    useEffect(() => {
        // Update the scroll position of the div on text change
        if (textDiv.current) textDiv.current.scrollTop = textDiv.current.scrollHeight;
    }, [chatLog[0]?.message]);

    useEffect(() => {
        if (textDiv.current) textDiv.current.scrollTop = 0;
    }, [chatLog[0]?.message?.includes("END.")]);

    const handleFlagClick = () => {
        // display popup window with options
        // if (displayFlag === true) {
        setPopup(true);
        // }
    };

    // useEffect(() => {
    //     const storedState = localStorage.getItem('chatboxState');
    //     if (storedState === 'storyPage') {
    //         // If the state is 'storyPage', show the StoryPage component
    //         setStoryPage(true);
    //     } else {
    //         // If the state is not found or is 'form', show the Form component
    //         setStoryPage(false);
    //     }
    // }, []);

    const handleLikeClick = async () => {
        console.log("before calling the like functions " + likeFeedbackId);
        if (thumbsUpClicked === true) {
            removeLike();
        } else {
            addLike();
        }
    };

    const handleOptionChange = (e) => {
        setReason(e.target.value);
    };

    const changeColor = (event) => {
        const label = event.target;
        label.classList.toggle("clicked-label");
    };

    const closePopup = () => {
        setPopup(false);
    };

    const resetForm = () => {
        setReportType(undefined);
        setOtherReason("");
    };
    const addLike = async () => {
        const newLikeFeedbackId = transId + "likefeedback";
        setLikeFeedbackId(newLikeFeedbackId);

        feedid = newLikeFeedbackId;

        // display new popup window with text "Thanks for your feedback!"
        setThumbsUpClicked(true);

        console.log(likeFeedbackId);

        const response = await fetch(updateUserFeedbackAddress, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                feedback_id: newLikeFeedbackId,
                trans_id: transId,
                user_id: email,
                feedback: "Like",
            }),
        });
    };

    const removeLike = async () => {
        // display new popup window with text "Thanks for your feedback!"
        setThumbsUpClicked(false);
        console.log("feedbackid in removeLike " + likeFeedbackId);

        const response = await fetch(updateUserFeedbackAddress, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                feedback_id: likeFeedbackId,
                trans_id: transId,
                user_id: email,
                feedback: "Remove Like",
            }),
        });
    };

    const submitFeedback = async () => {
        // display new popup window with text "Thanks for your feedback!"
        setThanksForFeedback(true);
        closePopup();
        setTimeout(function () {
            setThanksForFeedback(false);
        }, 2000);

        const response = await fetch(updateUserFeedbackAddress, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                feedback_id: uuidv4(),
                trans_id: transId,
                user_id: email,
                feedback: reason,
            }),
        });
    };
    const [otherReason, setOtherReason] = useState("");
    const [reportType, setReportType] = useState(undefined);

    return (
        <section className="chatbox-container" style={isFormSubmitted ? { flexDirection: "column" } : {}}>
            {slides.map((slide, index) => (
                <audio key={index} id={`myAudio-${index}`} />
            ))}

            {thanksForFeedback && (
                <div className="feedbackthanks" style={{ position: "absolute", top: "0%", left: "50%", box_shadow: "0 0 5px #ccc" }}>
                    <h5>Thanks for your feedback!</h5>
                </div>
            )}

            {popup && (
                <div
                    className="popup"
                    style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 9999,
                        transition: "left 0.3s ease-in-out",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", // Add box shadow here
                    }}
                >
                    <h4 style={{ marginTop: "1%", marginBottom: "1%", marginLeft: "1.3rem" }}>Report Story: </h4>
                    <div style={{ margin: "2%" }}>
                        <input
                            type="radio"
                            name="reason"
                            value="Inappropriate for kids"
                            onChange={handleOptionChange}
                            checked={reportType === 0} // Add this line to check if it's selected
                        />
                        <label className={reportType === 0 ? "clicked-label" : ""} style={{ margin: "2%", cursor: "pointer" }} onClick={() => setReportType(0)}>
                            Inappropriate for kids
                        </label>
                    </div>
                    <div style={{ margin: "2%", cursor: "pointer" }}>
                        <input
                            type="radio"
                            name="reason"
                            value="Other"
                            onChange={handleOptionChange}
                            checked={reportType === 1} // Add this line to check if it's selected
                        />
                        <label className={reportType === 1 ? "clicked-label" : ""} style={{ margin: "2%", cursor: "pointer" }} onClick={() => setReportType(1)}>
                            Other
                        </label>
                        <input
                            style={{ margin: "2%", width: "96%", height: "40px" }}
                            type="text"
                            name="otherReason"
                            onChange={(e) => setOtherReason(e.target.value)} // Update the otherReason state
                            value={reportType === 0 ? "Inappropriate for kids" : otherReason} // Use the otherReason state value
                        />
                    </div>

                    <div style={{ margin: "3%", display: "flex", flexDirection: "row", gap: "5px" }}>
                        <button className={`feedbackControls ${reportType === undefined || (reportType === 1 && otherReason.trim() === "") ? "disabled-button" : ""}`} type="button" onClick={submitFeedback} disabled={reportType === undefined || (reportType === 1 && otherReason.trim() === "")}>
                            Submit Feedback
                        </button>
                        <button
                            className="feedbackControls"
                            type="button"
                            onClick={() => {
                                closePopup();
                                resetForm();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}

            {!isFormSubmitted && (
                <ChatForm
                    moralOfStory={moralOfStory}
                    setMoralOfStory={setMoralOfStory}
                    language={language}
                    setLanguage={setLanguage}
                    handleSubmitWithLoading={handleSubmitWithLoading}
                    Age={Age}
                    setAge={setAge}
                    Topic={Topic}
                    setTopic={setTopic}
                    Characters={Characters}
                    loading={loading}
                    setCharacters={setCharacters}
                    //uncomment this code for poly/azure switch feature
                    // handleAudioOptionChange={handleAudioOptionChange}
                    // audioOption={audioOption}
                />
            )}
            {isFormSubmitted && (
                <>
                    <div className="carousel-container">
                        <div id="previous-btn" onClick={goToPreviousPage}>
                            <FontAwesomeIcon icon={faChevronLeft} size="2x" style={{ color: "#ffffff" }} />
                        </div>
                        <div className="leftborders">
                            <div className="div1"></div>
                            <div className="div2"></div>
                            <div className="div3"></div>
                            <div className="div4"></div>
                        </div>
                        <HTMLFlipBook width={550} height={550} size="stretch" minWidth={315} maxWidth={1000} minHeight={400} maxHeight={1533} ref={flipBookRef} disableFlipByClick useMouseEvents={false} flippingTime={500} mobileScrollSupport={false} onFlip={handleOnFlip} onInit={() => onInit()}>
                            {[...slides, ...Array(slides.length)].map((slide, index) => {
                                const _index = parseInt(index / 2);
                                return (
                                    <Page key={index}>
                                        <div id="page">
                                            {index % 2 === 0 ? (
                                                <StoryPage
                                                    PauseText={PauseText[_index]}
                                                    setPauseText={(text) => setSlidePauseText(_index, text)}
                                                    playButtonClass={playButtonClass[_index]}
                                                    setPlayButtonClass={(text) => setSlidePlayButtonClass(_index, text)}
                                                    _index={_index}
                                                    Audios={Audios}
                                                    language={language}
                                                    audio={Audios[_index]}
                                                    slide={slides[_index]}
                                                    slides={slides}
                                                    index={_index}
                                                    textDiv={textDiv}
                                                    loading={loading}
                                                    handleResumeClick={handleResumeClick}
                                                    handlePauseClick={handlePauseClick}
                                                    handleFlagClick={handleFlagClick}
                                                    goToNextPage={flipToNextSlide}
                                                    likeButtonColor={likeButtonColor}
                                                    handleLikeClick={handleLikeClick}
                                                    voices={voicesList}
                                                    currentStoryIndex={currentStoryIndex}
                                                    isLanguageSupported={isLanguageSupported}
                                                    voiceId={voiceId}
                                                />
                                            ) : (
                                                <div className="div6" style={images[_index] ? {} : { minWidth: "100%" }}>
                                                    {
                                                        language.toLowerCase() === "english" ? (
                                                            <>
                                                                {_index !== slides.length - 1 ? (
                                                                    <>
                                                                        {images[_index] ? (
                                                                            <img src={images[_index] ?? ArtWork} alt="art-image" />
                                                                        ) : (
                                                                            <div style={{ width: "100%", textAlign: "center" }} className="didivv6">
                                                                                Loading...
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ) : null}
                                                            </>
                                                        ) : (
                                                            // _index !== slides.length - 1 ? (
                                                            <>
                                                                {images[_index] ? (
                                                                    <img src={images[_index] ?? ArtWork} alt="art-image" />
                                                                ) : (
                                                                    <div style={{ width: "100%", textAlign: "center" }} className="didivv6">
                                                                        Loading...
                                                                    </div>
                                                                )}
                                                            </>
                                                        )
                                                        // ) : null
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </Page>
                                );
                            })}
                        </HTMLFlipBook>
                        <div className="rightBorders">
                            <div className="div4"></div>
                            <div className="div3"></div>
                            <div className="div2"></div>
                            <div className="div1"></div>
                        </div>
                        <div id="next-btn" onClick={goToNextPage}>
                            <FontAwesomeIcon icon={faChevronRight} size="2x" style={{ color: "#ffffff" }} />
                        </div>
                    </div>
                    <div id="reponsive-carousel" style={{ display: "none" }}>
                        <Slider {...settings} afterChange={slickHandleOnFlip}>
                            {[...slides].map((slide, index) => (
                                <div className="slick-wrapper" key={index}>
                                    <div id="slick-parent">
                                        <div style={{ display: "flex", flexDirection: "column", minWidth: "100%" }}>
                                            <div style={{ position: "relative" }}>
                                                <StoryPage
                                                    PauseText={PauseText[index]}
                                                    setPauseText={(text) => setSlidePauseText(index, text)}
                                                    playButtonClass={playButtonClass[index]}
                                                    setPlayButtonClass={(text) => setSlidePlayButtonClass(index, text)}
                                                    _index={index}
                                                    Audios={Audios}
                                                    audio={Audios[index]}
                                                    slide={slides[index]}
                                                    slides={slides}
                                                    index={index}
                                                    textDiv={textDiv}
                                                    loading={loading}
                                                    handleResumeClick={handleResumeClick}
                                                    handlePauseClick={handlePauseClick}
                                                    handleFlagClick={handleFlagClick}
                                                    goToNextPage={flipToNextSlide}
                                                    likeButtonColor={likeButtonColor}
                                                    handleLikeClick={handleLikeClick}
                                                    voices={voicesList}
                                                    currentStoryIndex={currentStoryIndex}
                                                    language={language}
                                                />
                                            </div>
                                            <div className="div6" style={images[index] ? {} : { minWidth: "100%" }}>
                                                {
                                                    language.toLowerCase() === "english" ? (
                                                        <>
                                                            {index !== slides.length - 1 ? (
                                                                <>
                                                                    {images[index] ? (
                                                                        <img src={images[index] ?? ArtWork} alt="art-image" />
                                                                    ) : (
                                                                        <div style={{ width: "100%", textAlign: "center" }} className="didivv6">
                                                                            Loading...
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : null}
                                                        </>
                                                    ) : (
                                                        // index !== slides.length - 1 ? (
                                                        <>
                                                            {images[index] ? (
                                                                <img src={images[index] ?? ArtWork} alt="art-image" />
                                                            ) : (
                                                                <div style={{ width: "100%", textAlign: "center" }} className="didivv6">
                                                                    Loading...
                                                                </div>
                                                            )}
                                                        </>
                                                    )
                                                    // ) : null
                                                }
                                            </div>
                                        </div>
                                        <div className="mobileBorders">
                                            <div className="div4"></div>
                                            <div className="div3"></div>
                                            <div className="div2"></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </>
            )}

            {isFormSubmitted && (
                <div className="sendStory">
                    <button type="submit" onClick={handleCreateStory}>
                        <img src={book} width="45" height="35" alt="book" />
                        <span>Create a new story</span>
                    </button>
                </div>
            )}

            {audioGenerated && (
                <div className="loading-overlay">
                    <span style={{ fontSize: "20px" }}>Audio generated! Click the play button to listen</span>
                </div>
            )}
            {isFormSubmitted && (
                <div
                    id="bottomLogo"
                    style={{
                        marginLeft: "20px",
                        marginTop: "30px",
                        marginBottom: "10px",
                    }}
                >
                    <img src={KisStory} alt="logo" width="100px" height="40px" />
                </div>
            )}
        </section>
    );
};

async function handleDefaultImg() {}

const splitDivIntoSlides = (text) => {
    const paragraphs = text.split("\n\n");
    const slides = paragraphs.map((paragraph) => paragraph.trim());
    return slides;
};

const Image = ({ img }) => {
    return (
        <div>
            <img src={img} alt="Welcome to JavaTpoint" width="600" height="540" />
        </div>
    );
};

export default memo(ChatBox);
