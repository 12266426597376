import React from 'react';

const ErrorFallback = ({ error, resetErrorBoundary }) => {

    const handleRedirect = () => {
        window.location = '/';
    };

    return (
        <div className="error-boundary">
            <h1>Unexpected error, please try again later</h1>
            <button onClick={handleRedirect}>Go to Home</button>
        </div>
    );
}

export default ErrorFallback;