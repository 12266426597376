import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Auth } from "aws-amplify";
import EasySpeech from "easy-speech";
import React, { useEffect, useMemo, useState } from "react";
import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import BillingPage from "./Billing";
import CancelSubscription from "./CancelSubscription";
import ChatBox from "./ChatBox";
import ContactUs from "./ContactUs";
import Dashboard from "./Dashboard";
import ForgotPassword from "./ForgotPassword";
import Help from "./Help";
import HomePage from "./Home";
import LoginPage from "./Login";
import PaymentPage from "./Payment";
import SubscriptionPlans from "./Plans";
import PrivacyPage from "./Privacy";
import UserProfile from "./Profile";
import RegisterPage from "./Register";
import "./SideMenu.css";
import TermsPage from "./Terms";
import ThankYouPage from "./ThankYou";
import ErrorFallback from "./components/ErrorFallBack";
import kLogo from "./images/k-logo.png";
import { AZURE_SUPPORTED_LANGUAGES } from "./languages";
import "./normal.css";
import OAuth2Callback from "./oauth2callback";
import OAuth2Callback_login from "./oauth2callback_login";
import { LanguagesSupported, Voices_Data } from "./utils/constants";
import { getSpeechAudio } from "./utils/polly";

var voices = [];

const subscriptionOptions = [
    { name: "basic", billing_frequency: "monthly", label: "Basic - 20 stories a month - $4.99 monthly", story_limit: 20, price: 4.99, price_id: "price_1N6OBzIcbvaTiSAsUZY6cfCL" },
    { name: "premium", billing_frequency: "monthly", label: "Premium - 40 stories a month - $6.99 monthly", story_limit: 40, price: 6.99, price_id: "price_1N6ODGIcbvaTiSAskm5TEYu1" },
    { name: "gold", billing_frequency: "monthly", label: "Gold - 60 stories a month - $9.99 monthly", story_limit: 60, price: 9.99, price_id: "price_1N6OEzIcbvaTiSAsS1NScDGw" },
    { name: "basic", billing_frequency: "annual", label: "Basic - 240 stories a year - $46.99 annual", story_limit: 240, price: 46.99, price_id: "price_1N3tYmIcbvaTiSAsbHXQbKDD" },
    { name: "premium", billing_frequency: "annual", label: "Premium - 480 stories a year - $66.99 annual", story_limit: 480, price: 66.99, price_id: "price_1N3tYmIcbvaTiSAseX6OADmQ" },
    { name: "gold", billing_frequency: "annual", label: "Gold - 720 stories a year - $94.99 annual", story_limit: 720, price: 94.99, price_id: "price_1N3tYmIcbvaTiSAsovhkK66y" },
];

const generateImageAddress = "https://4okcimituhlylqcffc5nf47teq0sfajy.lambda-url.us-east-2.on.aws/";
// const serverAddress =
const generateStoryAddress =
    // "http://localhost:3080/";
    "https://ocdvvx4e3mgmy62d7dwvztj2fe0ltxnr.lambda-url.us-east-2.on.aws/";
const getUserProfileAddress = "https://cidmp4byjeuct4uqk7lvhgbnja0gdhdc.lambda-url.us-east-2.on.aws/";

const credentials = {
    accessKeyId: "AKIARUIO25LGPG655F7N",
    secretAccessKey: "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",
};

const config = {
    credentials,
    region: "us-east-2",
    endpoint: "https://polly.us-east-2.amazonaws.com",
};

const awsconfig = {
    Auth: {
        region: "us-east-2",
        userPoolId: "us-east-2_3uYXEhMon",
        userPoolWebClientId: "1g6jutokcivrebiu2tf56fpj11",
    },
    credentials: {
        accessKeyId: "AKIARUIO25LGPG655F7N",
        secretAccessKey: "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",
    },
};

function App() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const [Age, setAge] = useState("");
    const [Topic, setTopic] = useState("");
    const [Characters, setCharacters] = useState("");
    const [models, setModels] = useState([]);
    const [temperature, setTemperature] = useState(1);
    const [currentModel, setCurrentModel] = useState("text-davinci-003");
    const [currentVoice, setCurrentVoice] = useState("Aria");
    const [playButtonClass, setPlayButtonClass] = useState("");
    const [moralOfStory, setMoralOfStory] = useState("");
    const [errored, setErrored] = useState(false);
    const [chatLog, setChatLog] = useState([
        // {
        //     user: "gpt",
        //     message: "Unexpected error, please try again later",
        // },
    ]);
    const [language, setLanguage] = useState("English");
    const [Audios, setAudios] = useState([]);
    const [pauseText, setpauseText] = useState([]);
    const [PlayButtonClass, setplayButtonClass] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentParagraph, setCurrentParagraph] = useState(0);
    const [audioGenerated, setAudioGenerated] = useState(false);

    const [PauseText, setPauseText] = useState("pause");
    const [stop, setStop] = useState(false);
    const [email, setEmail] = useState("");
    //const [voices, setVoices] = useState(null);

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [selectedVoice, setSelectedVoice] = useState("");

    const [selectedPlan, setSelectedPlan] = useState("basic");
    const [annualBilling, setAnnualBilling] = useState(false);
    const [existingConfirmation, setExistingConfirmation] = useState(false);

    const [userExtMessage, setUserExtMessage] = useState("");
    const [isLanguageSupported, setIsLanguageSupported] = useState(false);
    const [voiceId, setVoiceId] = useState("");

    //uncomment this code for poly/azure switch feature
    // const [audioOption, setAudioOption] = useState('Poly');
    // const handleAudioOptionChange = (event) => {
    //     setAudioOption(event.target.value);
    // };

    useEffect(() => {
        loadVoicesWhenAvailable();
    }, []);
    // sync, returns Object with detected features
    EasySpeech.detect();
    EasySpeech.init();
    //const audio = new Audio();
    //audio.autoplay = false;
    var audio = null;
    var audioCounter = 0;

    const [paragraphs, setParagraphs] = useState([]);
    var paragraphsArray = [];
    var audioURLsArray = [];

    var playingCounter = 0;
    var intervalId = "";

    const audioElement = new Audio();
    audioElement.autoplay = false;

    const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
    const stripePromise = loadStripe("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");
    // const stripePromise = loadStripe("pk_test_51MqTWNFIha26SJqwbgvkRvpFMX4qeN1oEpb4Ee2LuokIOyrKNGAPZ5EoGpo1a6tMSF86zos9lRzyhcu8fWVGNTfD00Hte8pS48");

    const [userData, setUserData] = useState({
        plan: "",
        active: false,
        last_billing_date: "",
        last_name: "",
        first_name: "",
        story_balance: "",
        email: "",
        join_date: "",
        plan_start_date: "",
        billing_customer_id: "",
        billing_frequency: "",
        cancelation_reason: "",
    });

    //alert("inside login");

    //Amplify.configure(awsconfig);

    Auth.configure(awsconfig);

    async function checkAuth() {
        try {
            await Auth.currentAuthenticatedUser();
            console.log("inside checkAuth");
            await setIsLoggedIn(true);
            console.log("IsLoggedIn was set to true");
            console.log(isLoggedIn);
            //return <LoginPage isLoggedIn = {isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>
        } catch (error) {
            setIsLoggedIn(false);
        }
    }

    useEffect(() => {
        // console.log(userData);
        //setSelectedPlan(userData.plan);

        if (userData.billing_frequency === "annual") {
            setAnnualBilling(true);
        } else {
            setAnnualBilling(false);
        }
    }, [userData]);

    async function handleSubmit(transId, _callback, _callback2) {
        audio = document.getElementById("myAudio");
        if (audio) audio.autoplay = false;

        async function generateStory() {
            const response = await fetch(generateStoryAddress, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    age: Age,
                    topic: Topic,
                    characters: Characters,
                    transId: transId,
                    currentModel,
                    email: email,
                    language: language,
                    moral: moralOfStory,
                }),
            });
            return response;
        }

        async function setStory(response, _callback, _callback2) {
            let image = "";
            let message = "";
            let paragraph = "";
            let numberOfParagraphs = 0;
            const paragraphsArray = [];

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            const readChunk = async () => {
                const { done, value } = await reader.read();
                if (done) {
                    console.log("No more data in response.");
                    return;
                }

                const text = decoder.decode(value, { stream: true });

                if (text.includes("CACHEDIMAGE")) {
                    image = text.substring(11);
                    // setStoryImage(image);
                } else {
                    message += text;
                    paragraph += text;
                }

                if (text.includes("\n")) {
                    numberOfParagraphs++;
                    console.log("numberOfParagraphs " + numberOfParagraphs);
                    console.log("paragraph is " + paragraph);

                    if (numberOfParagraphs === 5 && image.length === 0) {
                        console.log("calling callback");
                        console.log("uniqueId is: " + transId);
                        _callback(message, transId);
                    }
                }

                if (message.includes("THE END.")) {
                    paragraphsArray.push(paragraph);
                    setParagraphs((current) => [...current, paragraph]);
                }

                // Read the next chunk
                await readChunk();
            };

            await readChunk();

            const isEnglished = language.toLowerCase().includes("english");
            if (!isEnglished && message.includes("THE END.")) message = message.replace("THE END.", "");

            setChatLog([{ user: "gpt", message: message.trim(), img: image }]);
        }

        const response = await generateStory().catch((error) => {
            console.error("Error generating story:", error);
        });

        await setStory(response, _callback, _callback2);
    }

    async function loadVoicesWhenAvailable() {
        let logmsg = "";
        var timer = setInterval(function () {
            voices = window.speechSynthesis.getVoices().filter((voice) => voice.lang.includes("en-"));
            // setVoicesList(voices);
            if (voices.length > 5) {
                logmsg = "length is: " + voices.length;
                clearInterval(timer);
            }
        }, 200);
    }

    const handleLogout = async () => {
        //e.preventDefault();
        try {
            console.log("logout");
            setIsLoggedIn(false);
            setEmail("");
            setUserData({
                plan: "",
                active: false,
                last_billing_date: "",
                last_name: "",
                first_name: "",
                story_balance: "",
                email: "",
                join_date: "",
                plan_start_date: "",
                billing_customer_id: "",
                billing_frequency: "",
                cancelation_reason: "",
            });
            setIsMenuOpen(false);
            localStorage.removeItem("kidstoryta_email");
            await Auth.signOut();
            //window.location.reload();
            window.location.href = "https://kidstoryta.ai/";
        } catch (error) {
            console.log(error);
        }
    };

    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleHomeClick = () => {
        window.location.href = "https://kidstoryta.ai";
    };

    const chatLogText = useMemo(() => chatLog[0]?.message ?? "", [chatLog]);
    const slides = useMemo(() => {
        if (chatLogText) return splitDivIntoSlides(chatLogText, 500);
        else return [];
    }, [chatLogText]);

    const [images, setImages] = useState([]);

    /**
     *
     * @param {REMOVE_ME} story
     * @returns
     * REMOVE THIS AFTER IMAGE LAMBDA IS FIXED....
     */
    // async function generateImage(_story) {
    //     // const halfLength = Math.floor(chatLogText.length / 2);
    //     // const firstHalf = chatLogText.slice(0, halfLength);

    //     const imgResponse = await fetch(generateImageAddress, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({ "inputString": _story }),
    //     });
    //     const imgData = await imgResponse.json();
    //     return imgData
    // }

    /**
     *
     * @param {ENABLE_ME} TODO: ENABLE ME
     * @returns
     * ENABLE THIS, UNCOMMENT IT AFTER IMAGE LAMBDA IS FIXED....
     */
    async function generateImage(story) {
        const imgResponse = await fetch(generateImageAddress, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ inputString: typeof story === "string" ? story : story.join("\n\n") }),
        });
        const imgData = await imgResponse.json();
        return imgData;
    }

    const getVoiceFromVoicesList = (index) => {
        const voice = Voices_Data[index];
        if (!voice) return null;
        if (voice.female) {
            if (Array.isArray(voice.female)) return voice.female[0];
            else return voice.female;
        } else {
            if (Array.isArray(voice.male)) return voice.male[0];
            else return voice.male;
        }
    };

    const generateContent = async () => {
        if (slides.length && slides.length > 0) {
            const tokenPromise = fetch("https://eastus.api.cognitive.microsoft.com/sts/v1.0/issuetoken", {
                method: "POST",
                headers: {
                    "X-Microsoft-OutputFormat": "riff-24khz-16bit-mono-pcm",
                    "Content-Length": "187",
                    Accept: "*/*",
                    Connection: "keep-alive",
                    "Ocp-Apim-Subscription-Key": "8672de3b908e444fa2bc0f77c6c75379",
                },
            }).then((token) => token.text());

            const tokenText = await tokenPromise;
            const currentLanguage = Object.values(AZURE_SUPPORTED_LANGUAGES).find(({ language: _language }) => _language === language);
            const code = Object.keys(AZURE_SUPPORTED_LANGUAGES).find((key) => AZURE_SUPPORTED_LANGUAGES[key].language === language);

            const extractAudios = async () => {
                try {
                    return await Promise.all(
                        slides.map(async (slide) => {
                            let url = null;
                            const voiceIndex = Voices_Data.findIndex((item) => item.languageName.toLocaleLowerCase().includes(currentLanguage.language.toLocaleLowerCase()));
                            if (/*audioOption === "Poly" ||*/ LanguagesSupported.includes(currentLanguage.language) && voiceIndex !== -1) {
                                const voice = getVoiceFromVoicesList(voiceIndex);
                                setVoiceId(voice);
                                setIsLanguageSupported(true);
                                url = await getSpeechAudio(slide, voice);
                            } else {
                                setIsLanguageSupported(false);
                                const response = await fetch("https://westus.tts.speech.microsoft.com/cognitiveservices/v1", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/ssml+xml",
                                        "X-Microsoft-OutputFormat": "riff-24khz-16bit-mono-pcm",
                                        Host: "eastus.api.cognitive.microsoft.com",
                                        Authorization: "Bearer " + tokenText,
                                    },
                                    body: `<speak version='1.0' xml:lang='${code}'><voice xml:lang='${code}' xml:gender='Female' ` + `name='${currentLanguage.voices.female ?? currentLanguage.voices.male}'> ` + slide + "</voice></speak>",
                                });
                                const blob = await response.blob();
                                url = URL.createObjectURL(blob);
                            }
                            return url;
                        })
                    );
                } catch (error) {
                    return extractAudios();
                }
            };

            const firstFourParagraphs = slides.slice(0, 2);
            // const remainingPragraphs = slides.slice(4, slides.length - 1);

            const [_audios, genImages] = await Promise.all([extractAudios(), generateImage(firstFourParagraphs)]);

            // setTimeout(async () => {
            //     const remainingPragraphsResults = await generateImage(remainingPragraphs);
            //     if (remainingPragraphsResults && remainingPragraphsResults.success) {
            //         const { results } = remainingPragraphsResults;
            //         setImages(imgs => [...imgs, ...results.map(({ image }) => image) ?? []]);
            //     }
            // }, 30000); // 30 seconds for now.

            if (genImages?.success) {
                const { results } = genImages;
                setImages(results.map(({ image }) => image) ?? []);
            } else {
                console.log("Erorr in image generating LAMBDA");
                // return setErrored(true)
            }
            setAudios(_audios);
            setIsFormSubmitted(true);
            setLoading(false);

            // const remainingPragraphsResults = await generateImage(remainingPragraphs);
            // if (remainingPragraphsResults && remainingPragraphsResults.success) {
            //     const { results } = remainingPragraphsResults;
            //     setImages(imgs => [...imgs, ...results.map(({ image }) => image) ?? []]);
            // }

            for (let i = 2; i < slides.length; i = i + 2) {
                const remainingPragraphs = slides.slice(i, i + 2);

                const imagesResult = await generateImage(remainingPragraphs);
                if (imagesResult?.success) {
                    const { results } = imagesResult;
                    setImages((imgs) => [...imgs, ...(results.map(({ image }) => image) ?? [])]);
                } else setImages((imgs) => [...imgs, null]);
            }
        }
    };

    useEffect(() => {
        if (chatLogText === "Unexpected error, please try again later" || errored) {
        } else {
            generateContent();
            setpauseText([...Array(slides.length)].fill("resume"));
            setplayButtonClass([...Array(slides.length)].fill("play-button pulsate"));
        }
        return () => {};
    }, [slides]);

    const chatBoxEl = (
        <ChatBox
            isFormSubmitted={isFormSubmitted}
            setIsFormSubmitted={setIsFormSubmitted}
            setLanguage={setLanguage}
            language={language}
            Age={Age}
            moralOfStory={moralOfStory}
            setMoralOfStory={setMoralOfStory}
            images={images}
            slides={slides}
            setPauseText={setpauseText}
            Audios={Audios}
            loading={loading}
            setLoading={setLoading}
            playButtonClass={PlayButtonClass}
            setPlayButtonClass={setplayButtonClass}
            PauseText={pauseText}
            Topic={Topic}
            Characters={Characters}
            chatLog={chatLog}
            setChatLog={setChatLog}
            setAge={setAge}
            setTopic={setTopic}
            setCharacters={setCharacters}
            handleSubmit={handleSubmit}
            selectedVoice={selectedVoice}
            setSelectedVoice={setSelectedVoice}
            email={email}
            setEmail={setEmail}
            userData={userData}
            setUserData={setUserData}
            audioGenerated={audioGenerated}
            setAudioGenerated={setAudioGenerated}
            setErrored={setErrored}
            setImages={setImages}
            setAudios={setAudios}
            isLanguageSupported={isLanguageSupported}
            voiceId={voiceId}
            //uncomment this code for poly/azure switch feature
            // audioOption={audioOption}
            // handleAudioOptionChange={handleAudioOptionChange}
        />
    );

    return (
        <div className="App">
            <audio id="myAudio"></audio>
            {chatLogText === "Unexpected error, please try again later" || errored ? (
                <ErrorFallback />
            ) : (
                <Router>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {email !== "" && (
                            <div className="user-icon-wrapper">
                                <img class="user-icon" src={kLogo} onClick={handleMenuClick} />
                            </div>
                        )}
                        {email === "" && (window.location.pathname === "/" || window.location.pathname === "/login" || window.location.pathname === "/register") && (
                            <div className="user-icon-wrapper">
                                <img class="user-icon" src={kLogo} onClick={handleHomeClick} />
                            </div>
                        )}

                        {isMenuOpen && (
                            <div class="side-menu ">
                                <nav id="kids_main_nav" class="menu-position-right">
                                    <ul data-breakpoint="800" className="side_menu_ul">
                                        <li class="side_menu_li">
                                            <Link class="side_menu_link" to="/" onClick={() => setIsMenuOpen(false)}>
                                                Story
                                            </Link>
                                        </li>
                                        <li class="side_menu_li">
                                            <Link class="side_menu_link" to="/UserProfile" onClick={() => setIsMenuOpen(false)}>
                                                Profile
                                            </Link>
                                        </li>
                                        <li class="side_menu_li">
                                            <Link class="side_menu_link" to="/Help" onClick={() => setIsMenuOpen(false)}>
                                                Help
                                            </Link>
                                        </li>
                                        <li class="side_menu_li">
                                            <Link class="side_menu_link" to="/Plans" onClick={() => setIsMenuOpen(false)}>
                                                Upgrade
                                            </Link>
                                        </li>
                                        <li class="side_menu_li">
                                            <Link class="side_menu_link" to="/Billing" onClick={() => setIsMenuOpen(false)}>
                                                Billing
                                            </Link>
                                        </li>
                                        <li class="side_menu_li">
                                            <Link class="side_menu_link" onClick={() => handleLogout()}>
                                                Logout
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        )}

                        <div className="routes-container">
                            <Routes>
                                <Route exact path="/" element={chatBoxEl} />
                                <Route exact path="/Home" element={<HomePage />} />
                                <Route exact path="/OAuth2Callback" element={<OAuth2Callback />} />
                                <Route exact path="/OAuth2Callback_login" element={<OAuth2Callback_login />} />
                                <Route exact path="/Login" element={<LoginPage isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} email={email} setEmail={setEmail} userData={userData} setUserData={setUserData} userExtMessage={userExtMessage} />} />
                                <Route exact path="/register" element={<RegisterPage selectedPlan={selectedPlan} annualBilling={annualBilling} userData={userData} setUserData={setUserData} userExtMessage={userExtMessage} setUserExtMessage={setUserExtMessage} />} />
                                <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
                                <Route exact path="/UserProfile" element={<UserProfile selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} annualBilling={annualBilling} setAnnualBilling={setAnnualBilling} userData={userData} setUserData={setUserData} userExtMessage={userExtMessage} />} />
                                <Route exact path="/ContactUs" element={<ContactUs />} />
                                <Route exact path="/Help" element={<Help email={email} setEmail={setEmail} />} />
                                <Route
                                    exact
                                    path="/Plans"
                                    element={
                                        <SubscriptionPlans
                                            selectedPlan={selectedPlan}
                                            setSelectedPlan={setSelectedPlan}
                                            annualBilling={annualBilling}
                                            setAnnualBilling={setAnnualBilling}
                                            userData={userData}
                                            setUserData={setUserData}
                                            userExtMessage={userExtMessage}
                                            setUserExtMessage={setUserExtMessage}
                                        />
                                    }
                                />
                                <Route exact path="/Billing" element={<BillingPage userData={userData} setUserData={setUserData} />} />
                                <Route exact path="/Terms" element={<TermsPage />} />
                                <Route exact path="/ThankYou" element={<ThankYouPage selectedPlan={selectedPlan} annualBilling={annualBilling} userData={userData} setUserData={setUserData} userExtMessage={userExtMessage} />} />
                                <Route exact path="/Privacy" element={<PrivacyPage />} />
                                <Route exact path="/CancelSubscription" element={<CancelSubscription userData={userData} setUserData={setUserData} userExtMessage={userExtMessage} setUserExtMessage={setUserExtMessage} />} />
                                <Route
                                    path="/payment"
                                    element={
                                        <Elements stripe={stripePromise}>
                                            <PaymentPage selectedPlan={selectedPlan} annualBilling={annualBilling} userData={userData} setUserData={setUserData} userExtMessage={userExtMessage} setUserExtMessage={setUserExtMessage} />
                                        </Elements>
                                    }
                                />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route exact path="/index.html" element={chatBoxEl} />
                            </Routes>
                        </div>
                    </div>
                </Router>
            )}
        </div>
    );
}

const splitDivIntoSlides = (text) => {
    const paragraphs = text.split("\n\n");
    const slides = paragraphs.map((paragraph) => paragraph.trim());
    return slides.filter(Boolean);
};

export default App;
