import { faBookOpen, faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useLayoutEffect, useState } from "react";
import "./chatbox.css";
import ChatMessage from "./ChatMessage";

const StoryPage = ({ language, slide, audio, index, _index, textDiv, loading, handleFlagClick, likeButtonColor, handleLikeClick, currentStoryIndex, Audios, goToNextPage = () => {}, playButtonClass, setPlayButtonClass, PauseText, setPauseText, isLanguageSupported, voiceId }) => {
    const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleTimeUpdate = (e) => {
        setCurrentTime(Math.floor(e.target.currentTime * 1000));
    };

    const handleAudioEnded = () => {
        console.log("ended audio");
        var audio = document.getElementById(`myAudio-${index}`);
        if (audio) {
            audio.pause();
            audio.currentTime = 0;
        }
        setPauseText("resume");
        setPlayButtonClass("");
        goToNextPage();
        setIsPlaying(false);
    };

    useLayoutEffect(() => {
        var _audio = document.getElementById(`myAudio-${index}`);
        if (_audio) _audio.src = Audios[index];

        if (_audio) _audio.addEventListener("ended", handleAudioEnded);
        if (_audio) _audio.addEventListener("timeupdate", handleTimeUpdate);
        return () => {
            if (_audio) _audio?.removeEventListener("ended", handleAudioEnded);
            if (_audio) _audio.removeEventListener("timeupdate", handleTimeUpdate);
        };
        //eslint-disable-next-line
    }, [Audios]);

    const handlePauseClick = () => {
        var audio = document.getElementById(`myAudio-${index}`);
        if (audio) audio.pause();
        setPauseText("resume");
        setPlayButtonClass("");
        setIsPlaying(false);
    };

    const handleResumeClick = () => {
        const audio = document.getElementById(`myAudio-${index}`);
        if (audio) audio.play();
        setPauseText("pause");
        setIsPlaying(true);
    };

    return (
        <React.Fragment>
            <div id="storyparent" key={index}>
                {loading && (
                    <div className="book">
                        <FontAwesomeIcon icon={faBookOpen} spin size="6x" />
                    </div>
                )}
                {!loading && (
                    <div ref={textDiv} className="div5">
                        <ChatMessage isPlaying={isPlaying} language={language} audioSrc={audio} index={index} slideText={slide} currentTime={currentTime} isLanguageSupported={isLanguageSupported} voiceId={voiceId} />
                    </div>
                )}
            </div>
            <div className="audio-button">
                <button className="pause" type="button" onClick={PauseText === "resume" ? handleResumeClick : handlePauseClick}>
                    {PauseText === "resume" && <FontAwesomeIcon className={playButtonClass} icon="fa-solid fa-play" />}
                    {PauseText === "pause" && <FontAwesomeIcon icon="fa-solid fa-pause" />}
                    Read to me
                </button>
            </div>
            <div className="story-actions">
                {/* <div>
                    <FontAwesomeIcon icon={faFlag} color="white" size="1x" style={{ cursor: "pointer" }} onClick={handleFlagClick} />
                </div> */}
                <div>
                    <FontAwesomeIcon icon="fa-solid fa-thumbs-up" color={likeButtonColor} style={{ cursor: "pointer" }} onClick={handleLikeClick} />
                </div>
            </div>
        </React.Fragment>
    );
};
export default StoryPage;
