import Lottie from "lottie-react";
import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import bookAnimation from './Render.json';
import "./countDown.css";
import KisStory from "./images/create-story.png";
const CountdownOverlay = () => {
  const renderTime = ({ remainingTime }) => {
    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
      </div>
    );
  };

  return (
    <div className="overlay-container">
      <div className="timer-message">
        <h1>Your story is being created!</h1>
        <p>This takes less than a minute. Hang tight</p>
      </div>
      <div className="timer-wrapper">
        <div className="animation-image">
          <Lottie animationData={bookAnimation} loop={true} />
        </div>
        <CountdownCircleTimer
          isPlaying
          duration={60}
          size={150}
          colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
          colorsTime={[10, 6, 3, 0]}
          onComplete={() => ({ shouldRepeat: true, delay: 1 })}
        >
          {renderTime}
        </CountdownCircleTimer>
      </div>
      <div className="bottom-logo">
        <img src={KisStory} alt="logo" width="100px" height="40px" />
      </div>
    </div>

  );
};

export default CountdownOverlay;
