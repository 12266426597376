import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import KisStory from "./images/create-story.png";
import kLogo from "./images/k-logo.png";
import './thankyou.css';

const ThankYouPage = ({ selectedPlan, annualBilling, userData, setUserData, userExtMessage }) => {

  var billingFrequency = annualBilling ? 'annual' : 'monthly';
  const navigate = useNavigate();


  const subscriptionOptions = [
    { name: 'basic', billing_frequency: 'monthly', label: "Basic - 20 stories a month - $4.99 monthly", story_limit: 20, price: 4.99, price_id: 'price_1N6OBzIcbvaTiSAsUZY6cfCL' },
    { name: 'premium', billing_frequency: 'monthly', label: "Premium - 40 stories a month - $6.99 monthly", story_limit: 40, price: 6.99, price_id: 'price_1N6ODGIcbvaTiSAskm5TEYu1' },
    { name: 'gold', billing_frequency: 'monthly', label: "Gold - 60 stories a month - $9.99 monthly", story_limit: 60, price: 9.99, price_id: 'price_1N6OEzIcbvaTiSAsS1NScDGw' },
    { name: 'basic', billing_frequency: 'annual', label: "Basic - 240 stories a year - $46.99 annual", story_limit: 240, price: 46.99, price_id: 'price_1N3tYmIcbvaTiSAsbHXQbKDD' },
    { name: 'premium', billing_frequency: 'annual', label: "Premium - 480 stories a year - $66.99 annual", story_limit: 480, price: 66.99, price_id: 'price_1N3tYmIcbvaTiSAseX6OADmQ' },
    { name: 'gold', billing_frequency: 'annual', label: "Gold - 720 stories a year - $94.99 annual", story_limit: 720, price: 94.99, price_id: 'price_1N3tYmIcbvaTiSAsovhkK66y' }
  ];

  var finalAmt = subscriptionOptions.find(option => option.name === selectedPlan && option.billing_frequency === billingFrequency)?.price;
  var price_id = subscriptionOptions.find(option => option.name === selectedPlan && option.billing_frequency === billingFrequency)?.price_id;
  var story_balance = subscriptionOptions.find(option => option.price_id === price_id)?.story_limit;

  const handleStoriesClick = async (e) => {
    navigate('/', selectedPlan, annualBilling, userData, setUserData, userExtMessage);
  }

  return (
    <div className="Thankyou-page">
      <Helmet>
        <script>
          {`
            {
              gtag('event', 'conversion', {
                'send_to': 'AW-11173533642/J9B3CPT57Z0YEMqv-s8p',
                'value': 1.0,
                'currency': 'USD',
                'transaction_id': ''
              });
            }
          `}
        </script>
        <script>
          {`
           !function(f,b,e,v,n,t,s)
           {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
           n.callMethod.apply(n,arguments):n.queue.push(arguments)};
           if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
           n.queue=[];t=b.createElement(e);t.async=!0;
           t.src=v;s=b.getElementsByTagName(e)[0];
           s.parentNode.insertBefore(t,s)}(window, document,'script',
           'https://connect.facebook.net/en_US/fbevents.js');
           fbq('init', '579435284179835');
           fbq('track', 'Purchase');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=579435284179835&ev=PageView&noscript=1" />`}
        </noscript>
      </Helmet>
      <div className="form-logo-section">
        <img src={KisStory} alt="story-book" />
      </div>
      <h1 className='plan-title-head'>Thank You</h1>
      <div>
        <p className='thankyou-text textGap'><strong>You have subscribed to the {selectedPlan} plan</strong></p>
        <p className='thankyou-text'><strong>successfully! Happy story creating!</strong></p>
      </div>
      <div className="thankyou-btn">
        <button id="thankyouBtn" onClick={handleStoriesClick} type='submit'>Start the Story Magic</button>
      </div>
      {/* <div className="top-logo">
        <img src={kLogo} alt="logo" width="100px" height="40px" />
      </div> */}
    </div>
  );
};


export default ThankYouPage;
