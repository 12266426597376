import { Auth } from "aws-amplify";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './billing.css';
import KisStory from "./images/create-story.png";

const BillingPage = ({ userData, setUserData }) => {

  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  //const stripePromise = loadStripe('pk_test_51MqTWNFIha26SJqwbgvkRvpFMX4qeN1oEpb4Ee2LuokIOyrKNGAPZ5EoGpo1a6tMSF86zos9lRzyhcu8fWVGNTfD00Hte8pS48');

  //const stripe = require('stripe')('pk_test_51MqTWNFIha26SJqwbgvkRvpFMX4qeN1oEpb4Ee2LuokIOyrKNGAPZ5EoGpo1a6tMSF86zos9lRzyhcu8fWVGNTfD00Hte8pS48');
  const stripe = require('stripe')('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');

  useEffect(() => {
    async function getCurrentUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log("Logged in user email is: " + user.attributes.email);
      } catch (error) {
        console.log("error getting current user from Cognito, let's check google " + error);
        //check if user authenticated thru google
        const googleEmail = localStorage.getItem('kidstoryta_email');
        if (!googleEmail) {
          navigate('https://kidstoryta.ai/');
        }
      }
    }
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (userData.billing_customer_id !== 'temp') {
      stripe.paymentIntents.list(
        { limit: 50, customer: userData.billing_customer_id },
        (err, paymentIntents) => {
          if (err) {
            console.error(err);
            return;
          }
          setPaymentList(paymentIntents.data);
          setLoading(false)
        }
      );
    } else{
      setLoading(false);
    }
  }, []);

  console.log({ paymentList })

  return (
    <div className="billing">
      <div className="form-logo-section">
        <img src={KisStory} alt="story-book" />
      </div>
      <h2 className="payment-title-head">Payment History</h2>
      {/* <div className="top-logo">
        <img src={kLogo} alt="logo" width="100px" height="40px" />
      </div> */}
      <div className="pay-table">
        <div id="tablehead">
          <h3>Payment Amount</h3>
          <h3>Status</h3>
          <h3 style={{ maxWidth: '120px' }}>Date</h3>
        </div>
        {loading ? (
          <div id="noPayment">
            <h3 style={{ maxWidth: '350px', fontSize: '18px' }}>Loading payment history...</h3>
          </div>
        ) : (
          <>
            {paymentList.length > 0 ? (
              paymentList.map((payment, index) => (
                <div id="tablehead" key={index} style={{ marginTop: '10px' }}>
                  <h3>${(payment.amount / 100).toFixed(2)}</h3>
                  <h3 style={{ wordWrap: 'break-word'}}>{payment.status}</h3>
                  <h3 style={{ maxWidth: '120px' }}>{new Date(payment.created * 1000).toLocaleDateString()}</h3>
                </div>
              ))
            ) : (
              <div id="noPayment">
                <h3 style={{fontSize:'18px'}}>There is no payment yet</h3>
              </div>
            )}
          </>
        )}

      </div>
    </div>

  );

};

export default BillingPage;
