import React, { Component } from 'react'


class ErrorBoundaryReact extends Component {
  state = { error: null };

  componentDidCatch(error) {
    this.setState({ error });
  }

  handleRedirect = () => {
    window.location = '/';
  };

  render() {
    const { error } = this.state;

    if (error) {
      return (
        <div className="error-boundary">
          <h1>Something went wrong</h1>
          <button onClick={this.handleRedirect}>Go to Home</button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaryReact;
