import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import KisStory from "./images/create-story.png";
import Google from './images/Google.png';
import './Login.css';

const LoginPage = ({ isLoggedIn, setIsLoggedIn, email, setEmail, userData, setUserData, userExtMessage }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(userExtMessage ? userExtMessage : "");

  const awsconfig = {
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_3uYXEhMon',
      userPoolWebClientId: '1g6jutokcivrebiu2tf56fpj11'
    },
    credentials: {
      accessKeyId: 'AKIARUIO25LGPG655F7N',
      secretAccessKey: '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'
    }
  };

  //alert("inside login");

  //Amplify.configure(awsconfig);

  Auth.configure(awsconfig);

  const navigate = useNavigate();

  //console.log(Auth.currentAuthenticatedUser());

  const getUserProfileAddress = "https://cidmp4byjeuct4uqk7lvhgbnja0gdhdc.lambda-url.us-east-2.on.aws/";

  async function getUserProfile(email) {
    const response = await fetch(getUserProfileAddress, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email
      })
    });
    return response;
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    // Show spinner and disable button
    document.getElementById('loginSpinner').style.display = "inline-block";
    document.getElementById('login-text').style.display = "none";
    document.getElementById('loginBtn').disabled = true;
    try {
      setEmail(username);
      await Auth.signIn(username, password);
      console.log("sign in successful");
      setIsLoggedIn(true);
      // Redirect to the authenticated page
      /*
      This is not needed since we are getting the profile in chatbox
      const userProfile = await getUserProfile(username).catch((error) => {
                  console.error("Error getting user profile to DB:", error);
                });
      const userProfileData = await userProfile.json();
      await setUserData(userProfileData);
      console.log(userData);*/

      navigate('/');
      // Hide spinner and enable button
      document.getElementById('loginSpinner').style.display = 'none';
      document.getElementById('login-text').style.display = "inline-block";
      document.getElementById('loginBtn').disabled = false;
    } catch (error) {
      //console.log(error.code);
      //if ( error.code === 'UserNotConfirmedException') {
      //      navigate("/register", { state: { existingEmail: username, existingConfirmation: true, existingPassword: password } });
      //}
      setErrorMessage(error.message);
      // Hide spinner and enable button
      document.getElementById('loginSpinner').style.display = 'none';
      document.getElementById('login-text').style.display = "inline-block";
      document.getElementById('loginBtn').disabled = false;
    }
  };

  const handleGoogleSignInCustom = (event) => {
    event.preventDefault();
    window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?' +
      'response_type=code' +
      '&client_id=540130539757-4euhj1snjiq8tupvntfbgbv53qucpl02.apps.googleusercontent.com' +
      '&redirect_uri=https://app.kidstoryta.ai/oauth2callback_login' +
      '&max_auth_age=0'+
      '&prompt=select_account+consent'+
      '&scope=email profile openid';
  }

  return (
    <div className="login-container">
      <div className="form-logo-section">
        <img src={KisStory} alt="story-book" />
      </div>
      <h1 className='help-title-head'>Welcome back</h1>
      <div className='login-inputs'>
        <form onSubmit={handleLogin}>
          <div className='login-feilds'>
            <input type='text' placeholder='name@emailaddress.com'
              id='username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </form>
      </div>
      <p className='forgot-password'><Link to="/ForgotPassword"><span>Forgot Password? </span></Link></p>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="help-btn" onClick={handleLogin}>
        <button id="loginBtn" type='submit'>
          <span id='login-text' style={{ display: 'inline-block' }}>Login</span>
          <span id="loginSpinner" style={{ position: 'absolute', top: '54%', left: '50%', transform: 'translate(-50%, -50%)', display: 'none' }}>
            <i className="fa fa-spinner fa-spin"></i>
          </span>
        </button>
      </div>
      <p className='or-text'>OR</p>
      <div className='google-btn'>
        <button class="rounded-button" onClick={handleGoogleSignInCustom}>
          <img src={Google} alt="Google Logo" class="google-logo" />
          <span class="button-text">Continue with Google</span>
        </button>
      </div>
      <div className="link linkmt">
        New to Kidstoryta? <Link to="/register"><span>Sign up</span></Link>
      </div>
      <p className='link'>By signingin your accept our <Link to="https://kidstoryta.ai/terms-and-conditions/"><span>Term and Conditions</span></Link></p>
    </div>
  );
};

export default LoginPage;
