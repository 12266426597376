import { Polly, S3 } from "aws-sdk";
import * as uuid from "uuid";

const credentials = {
    accessKeyId: "AKIARUIO25LGPG655F7N",
    secretAccessKey: "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",
};

const config = {
    credentials,
    region: "us-east-1",
    endpoint: "https://polly.us-east-1.amazonaws.com",
};

const polly = new Polly(config);
const s3 = new S3({ ...config, region: "us-east-1" });

s3.config.update({
    region: "us-east-1",
    s3BucketEndpoint: false,
    endpoint: "https://s3.amazonaws.com",
});

export const getSpeechAudio = (text, voice) => {
    return new Promise((resolve, reject) => {
        polly.synthesizeSpeech(
            {
                OutputFormat: "mp3",
                Text: text,
                VoiceId: voice || "Joanna",
                Engine: "neural",
            },
            (err, data) => {
                if (err) {
                    console.error(err.toString());
                    return;
                }

                s3.upload(
                    {
                        Body: data.AudioStream,
                        Bucket: "kidotales-audios",
                        Key: `${uuid.v4()}.mp3`,
                        ContentType: "audio/mpeg",
                    },
                    (err, data) => {
                        if (err) {
                            console.error("err", err.toString());
                            reject(err);
                            return;
                        }
                        resolve(data.Location);
                    }
                );
            }
        );
    });
};

export const getSpeechMarks = (text, voice) => {
    return new Promise((resolve, reject) => {
        polly.synthesizeSpeech(
            {
                OutputFormat: "json",
                SpeechMarkTypes: ["word", "sentence"],
                // Valid Values: sentence | ssml | viseme | word
                Text: text,
                VoiceId: voice || "Joanna",
                Engine: "neural",
            },
            async (err, data) => {
                if (err) {
                    console.error(err.toString());
                    reject(err);
                    return;
                }

                const speechMarks = data.AudioStream;

                const marks = await speechMarks.toString();

                const lines = marks.split("\n");

                // Initialize an array to store parsed JSON objects
                const jsonArray = [];

                // Parse each line and add it to the array
                lines.forEach((line) => {
                    try {
                        const jsonObject = JSON.parse(line);
                        jsonArray.push(jsonObject);
                    } catch (error) {
                        console.error("Error parsing line:", line);
                    }
                });
                return resolve(jsonArray);
            }
        );
    });
};

export function updateMarks(sentences, marks, language, data) {
    console.log(data);
    if (language.toLowerCase().includes("english")) {
        const sentence = sentences
            .map(({ value }) => value)
            .reduce((acc, value) => acc + ` ${value}`, "")
            .split(" ")
            .filter(Boolean);

        for (let i = 0; i < marks.length; i++) marks[i].value = sentence[i];

        return marks;
    } else {
        const mappedMarks = [...data];
        let index = 0;
        let currentSentence = mappedMarks.find((item) => item.type === "sentence").value;
        for (const item of mappedMarks) {
            if (item.type === "sentence") currentSentence = item;
            if (currentSentence?.value?.includes(`${item.value},`)) item.value = `${item.value},`;
            if (index + 1 == mappedMarks.length) mappedMarks[index].value = `${mappedMarks[index].value}.`;
            if (item.type === "sentence") if (mappedMarks[index - 1]) mappedMarks[index - 1].value = `${mappedMarks[index - 1].value}. `;

            index++;
        }

        return mappedMarks.filter(({ type }) => type == "word");
    }
}
