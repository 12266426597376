import { useElements } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import kLogo from "./images/k-logo.png";
import KisStory from "./images/create-story.png";
import './payment.css'

const PaymentPage = ({ selectedPlan, annualBilling, userData, setUserData, userExtMessage, setUserExtMessage }) => {

  var billingFrequency = annualBilling ? 'annual' : 'monthly';

  const subscriptionOptions = [
    { name: 'basic', billing_frequency: 'monthly', label: "Basic - 20 stories a month - $4.99 monthly", story_limit: 20, price: 4.99, price_id: 'price_1N6OBzIcbvaTiSAsUZY6cfCL' },
    { name: 'premium', billing_frequency: 'monthly', label: "Premium - 40 stories a month - $6.99 monthly", story_limit: 40, price: 6.99, price_id: 'price_1N6ODGIcbvaTiSAskm5TEYu1' },
    { name: 'gold', billing_frequency: 'monthly', label: "Gold - 60 stories a month - $9.99 monthly", story_limit: 60, price: 9.99, price_id: 'price_1N6OEzIcbvaTiSAsS1NScDGw' },
    { name: 'basic', billing_frequency: 'annual', label: "Basic - 240 stories a year - $46.99 annual", story_limit: 240, price: 46.99, price_id: 'price_1N3tYmIcbvaTiSAsbHXQbKDD' },
    { name: 'premium', billing_frequency: 'annual', label: "Premium - 480 stories a year - $66.99 annual", story_limit: 480, price: 66.99, price_id: 'price_1N3tYmIcbvaTiSAseX6OADmQ' },
    { name: 'gold', billing_frequency: 'annual', label: "Gold - 720 stories a year - $94.99 annual", story_limit: 720, price: 94.99, price_id: 'price_1N3tYmIcbvaTiSAsovhkK66y' }
  ];

  const plans = [
    {
      id: 'basic',
      name: 'Basic',
    },
    {
      id: 'gold',
      name: 'Gold',
    },
    {
      id: 'premium',
      name: 'Premium',
    },
  ];

  const finalAmt = subscriptionOptions.find(option => option.name === selectedPlan && option.billing_frequency === billingFrequency)?.price;
  const price_id = subscriptionOptions.find(option => option.name === selectedPlan && option.billing_frequency === billingFrequency)?.price_id;
  const story_balance = subscriptionOptions.find(option => option.price_id === price_id)?.story_limit;

  return (
    <div className="payment-container">
      <div className="form-logo-section">
        <img src={KisStory} alt="story-book" />
      </div>
      <h1 className='payment-title-head'>Payment</h1>
      <div className='inner-payment-container'>
        <div className='payment-card'>
          {plans.map((plan) => (
            selectedPlan === plan.id && (
              <React.Fragment key={plan.id}>
                <h2>{plan.name}</h2>
                <h2>${finalAmt}/{annualBilling ? 'year' : 'month'}</h2>
                <h4>{annualBilling ? '600' : '50'} stories per {annualBilling ? 'year' : 'month'}</h4>
              </React.Fragment>
            )
          ))}
        </div>
        <PaymentForm selectedPlan={selectedPlan} annualBilling={annualBilling} finalAmt={finalAmt} userData={userData} setUserData={setUserData} userExtMessage={userExtMessage} setUserExtMessage={setUserExtMessage} price_id={price_id} story_balance={story_balance} />
      </div>
      {/* <div className="top-logo">
        <img src={kLogo} alt="logo" width="100px" height="40px" />
      </div> */}
    </div>
  );
};

const PaymentForm = ({ selectedPlan, annualBilling, finalAmt, userData, setUserData, userExtMessage, setUserExtMessage, price_id, story_balance }) => {
  const navigate = useNavigate();
  // const stripe = useStripe();
  // const stripe = require('stripe')('pk_test_51MqTWNFIha26SJqwbgvkRvpFMX4qeN1oEpb4Ee2LuokIOyrKNGAPZ5EoGpo1a6tMSF86zos9lRzyhcu8fWVGNTfD00Hte8pS48');
  const elements = useElements();
  const [nameOnCard, setNameOnCard] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCvv] = useState("");
  
  const stripe = require('stripe')('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');
  const addUserProfileAddress = "https://wx7dlc2wezs7g47myix66g3k3m0wymxf.lambda-url.us-east-2.on.aws/";
  const [userMessage, setUserMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState('');

  const updateProfile = async () => {
    try {
      const response = await fetch(addUserProfileAddress, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(userData)
      });
      if (response !== null && response.status === 200) {
        setUserMessage("Profile was updated successfully");
      }
      else {
        setUserMessage("There was an issue with updating the profile, please try again later");
      }
    }
    catch (error) {
      setUserMessage("There was an issue with updating the profile, please try again later");
    }
  }

  useEffect(() => {
    const updateProfileAndNavigate = async () => {
      console.log("selectedPlan in payment is " + selectedPlan);
      if (userData.plan === selectedPlan) {
        await updateProfile();
        //setUserExtMessage("You have successfully subscribed to the " + userData.plan + " plan ");
        console.log("The userData has been updated to: " + userData.plan);
        // Hide spinner and enable button
        document.getElementById('subscribeSpinner').style.display = 'none';
        document.getElementById('login-text').style.display = "inline-block";
        document.getElementById('saveBtn').disabled = false;
        navigate('/ThankYou', selectedPlan, annualBilling, userData, setUserData, userExtMessage);
      }
    };
    updateProfileAndNavigate();
  }, [userData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (nameOnCard == '' || cardNumber == '' || expirationDate == '' || cvv == '') {
        setErrorMessage('Please fill out all the required fields');
        return;
      }
  
      if (!stripe) {
        console.log('Stripe is not loaded yet')
        console.log(stripe, elements)
        return;
      }

      // Show spinner and disable button
      document.getElementById('subscribeSpinner').style.display = "inline-block";
      document.getElementById('login-text').style.display = "none";
      document.getElementById('saveBtn').disabled = true;

      var customerId = userData.billing_customer_id;
      console.log("userData billing customer id " + userData.billing_customer_id);
      //if user doesn't have a billing customer id, create one in Stripe and update the database
      if (userData.billing_customer_id === 'temp') {
        const customer = await stripe.customers.create({
          email: userData.email
        });
        console.log("customer created " + customer.id);
        customerId = customer.id;
        console.log("selected plan before update " + selectedPlan);
        //setUserData({ ...userData, billing_customer_id: customer.id, plan: selectedPlan, billing_frequency: annualBilling? 'annual':'monthly' });
      }
      else {
        console.log("selected plan before update " + selectedPlan);
        //setUserData({ ...userData, plan: selectedPlan,billing_frequency: annualBilling? 'annual':'monthly' });
      }
      // Delete all subscriptions for the customer
      const subscriptions = await stripe.subscriptions.list({
        customer: customerId,
        limit: 100,
      });

      for (const subscription of subscriptions.data) {
        await stripe.subscriptions.del(subscription.id);
      }

      console.log(`Deleted ${subscriptions.data.length} subscriptions`);

      // Detach all payment methods for the customer
      const paymentMethods = await stripe.paymentMethods.list({
        customer: customerId,
        type: 'card',
        limit: 100,
      });

      for (const paymentMethod of paymentMethods.data) {
        await stripe.paymentMethods.detach(paymentMethod.id);
      }

      console.log(`Detached ${paymentMethods.data.length} payment methods`);

      const paymentMethod = await stripe.paymentMethods.create({
        type: 'card',
        card: {
          number: cardNumber,
          exp_month: expirationDate.substring(0, 2),
          exp_year: expirationDate.substring(3),
          cvc: cvv,
        },
        billing_details: {
          name: nameOnCard
        }
      });
      console.log("added payment method: " + paymentMethod.id);

      const attach = await stripe.paymentMethods.attach(
        paymentMethod.id,
        { customer: customerId }
      );

      console.log("attached payment method: " + paymentMethod.id);

      const subscription = await stripe.subscriptions.create({
        customer: customerId,
        items: [
          { price: price_id },
        ],
        default_payment_method: paymentMethod.id
      });

      console.log("subscription created: " + subscription.id);

      var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

      //set user data after subscription was created successfully
      setUserData({ ...userData, billing_customer_id: customerId, plan: selectedPlan, last_billing_date:localISOTime ,billing_frequency: annualBilling ? 'annual' : 'monthly', story_balance: story_balance, cancelation_reason: '' });



    } catch (error) {
      console.log('error subscribing:', error);
      setErrorMessage(error.message);
      // Hide spinner and enable button
      // Hide spinner and enable button
      document.getElementById('subscribeSpinner').style.display = 'none';
      document.getElementById('login-text').style.display = "inline-block";
      document.getElementById('saveBtn').disabled = false;
    }

  };

  const cardElementOptions = {
    style: {
      base: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
      },
      display: 'flex',
      flexDirection: 'column',
    }
  };

  function handleNameOnCardChange(event) {
    setNameOnCard(event.target.value);
    // Do something with the card number
  }

  function handleCardNumberChange(event) {
    setCardNumber(event.target.value);
    // Do something with the card number
  }

  function handleExpirationDateChange(event) {
    setExpirationDate(event.target.value);
    // Do something with the expiration date
  }

  function handleCvvChange(event) {
    setCvv(event.target.value);
    // Do something with the CVV
  }

  function handleBackClick(event) {
    navigate('/Plans');
    // Do something with the CVV
  }



  return (
    //<form onSubmit={handleSubmit} >
    <>
      <div className="profile-form">
        <div className="profile-fields">
          <h4>Name on Card</h4>
          <input type="text" className='profile-input' value={nameOnCard} onChange={handleNameOnCardChange} />
        </div>
        <div className="profile-fields">
          <h4>Credit/Debit Card Number :</h4>
          <input type="number" className='profile-input' value={cardNumber} onChange={handleCardNumberChange} />
        </div>
        <div className="crad-number">
          <div className="profile-fields">
            <h4>Expiration Date</h4>
            <input type="text" className='profile-input' value={expirationDate} placeholder='MM/YY' onChange={handleExpirationDateChange} />
          </div>
          <div className="profile-fields">
            <h4 style={{ minWidth: '50px' }}>CVV</h4>
            <input type="text" className='profile-input' value={cvv} onChange={handleCvvChange}/>
          </div>
        </div>
      </div>
      {errorMessage && (
        <div  className="error-message">{errorMessage}</div>
      )}
      <div className="payment-btn" style={{ gap: '10px', marginLeft: '40px' }}>
        <button id="paymentBtn" style={{ backgroundColor: '#2E83D8' }} onClick={handleBackClick}>
          Back
        </button>
        <button id="saveBtn" onClick={handleSubmit} style={{position:'relative'}}>
          <span id='login-text' style={{ display: 'inline-block' }}>Subscribe</span>
          <span id="subscribeSpinner" style={{ position: 'absolute', top: '54%', left: '50%', transform: 'translate(-50%, -50%)', display: 'none' }}>
            <i className="fa fa-spinner fa-spin"></i>
          </span>
        </button>
      </div>

      <div className='payment-footer'>
        <p>By clicking subscribe, you allow Kidstoryta™ to charge your card for
          this payment and future payments in the amount of ${finalAmt} per {annualBilling ? 'year' : 'month'} in
          accordance with their terms. You can always cancel your subscription.</p>
        <div className='payment-footer-link'>
          <a href="https://stripe.com" >Powered by <span style={{ color: '#E8E09F' }}>Stripe</span></a>
          <a href="https://stripe.com/legal/end-users">Terms</a>
          <a href="https://stripe.com/privacy">Privacy</a>
        </div>
      </div>
    </>
    // <div className='payment-container'>
    //   <table style={{ textAlign: 'center', justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
    //     <tbody>
    //       <tr>
    //         <td style={{ width: "20%", margin: "10px", borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>Name on Card:</td>
    //         <td style={{ borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
    //           <input style={{ width: "80%", margin: "10px" }} type="text" value={nameOnCard} onChange={handleNameOnCardChange} />
    //         </td>
    //       </tr>
    //       <tr>
    //         <td style={{ width: "20%", margin: "10px", borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>Credit/Debit Card Number:</td>
    //         <td style={{ borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
    //           <input style={{ width: "80%", margin: "10px" }} type="number" value={cardNumber} onChange={handleCardNumberChange} />
    //         </td>
    //       </tr>
    //       <tr>
    //         <td style={{ width: "20%", margin: "10px", borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>Expiration Date:</td>
    //         <td style={{ borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
    //           <input style={{ width: "80%", margin: "10px" }} type="text" className="input-placeholder" value={expirationDate} placeholder='MM/YY' onChange={handleExpirationDateChange} />
    //         </td>
    //       </tr>
    //       <tr>
    //         <td style={{ width: "20%", margin: "10px", borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>CVV:</td>
    //         <td style={{ borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
    //           <input style={{ width: "80%", margin: "10px" }} type="text" value={cvv} onChange={handleCvvChange} />
    //         </td>
    //       </tr>
    //       <tr>
    //         <td colSpan='2' style={{ borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
    //           {errorMessage && (
    //             <div style={{ width: '100%', color: 'red', textAlign: 'center' }} className="error-message">{errorMessage}</div>
    //           )}
    //         </td>
    //       </tr>
    //       <tr>
    //         <td style={{ width: "20%", margin: "10px", borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>

    //         </td>
    //         <td style={{ borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
    //           <button style={{ width: "25%", margin: '1%' }} type="submit" onClick={handleBackClick}>
    //             Back
    //           </button>
    //           <div style={{ position: 'relative', display: 'inline-block', width: "55%" }}>
    //             <button onClick={() => handleSubmit()} id="subscribeBtn" style={{ width: "100%", margin: '0%' }}>
    //               Subscribe
    //               <span id="subscribeSpinner" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: 'none' }}>
    //                 <i className="fa fa-spinner fa-spin"></i>
    //               </span>
    //             </button>
    //           </div>
    //         </td>
    //       </tr>
    //     </tbody>
    //   </table>
    // </div>
  );
};

export default PaymentPage;
