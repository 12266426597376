import React from 'react';
import ReactDOM from 'react-dom/client';
// import { ErrorBoundary } from "react-error-boundary";
import App from './App';
import ErrorFallback from './components/ErrorFallBack';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { library } from '@fortawesome/fontawesome-svg-core';

import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import ErrorBoundaryReact from './components/ErrorBoundary';

library.add(fab)
library.add(fas)
library.add(far)

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <ErrorBoundaryReact>
      <App />
    </ErrorBoundaryReact>
  </React.StrictMode>
);


reportWebVitals();
