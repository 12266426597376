import React, { useState, useEffect } from 'react';
import './images/favicon.png';
import './css/font-awesome.css';
import './css/styles.css';
import './css/maps.css';
import './css/woocommerce.css';
//import './css/flexnav.css';
import './css/prettyPhoto.css';
import './revslider/styles.css';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import { Auth, Amplify } from "aws-amplify";
import Slideshow from './Slideshow';

const TermsPage = () => {

  const awsconfig = {
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_3uYXEhMon',
      userPoolWebClientId: '1g6jutokcivrebiu2tf56fpj11'
    },
    credentials: {
      accessKeyId: 'AKIARUIO25LGPG655F7N',
      secretAccessKey: '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'
    }
  };

  Auth.configure(awsconfig);

  const sendEmailAddress = "https://hdbvjflnhfp6vvvdditgfgsruq0futml.lambda-url.us-east-2.on.aws/";

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");

  var screenWidth = window.innerWidth;
  // Set the startwidth and startheight based on the screen width
  var location = screenWidth > 1024 ? 'center right' : 'center top';
  var captionWidth = screenWidth > 1024 ? '45%' : '97%';
  var titleDataX = screenWidth > 1024 ? '7' : '30';
  var titleDataY = screenWidth > 1024 ? '50' : '320';
  var captionDataX = screenWidth > 1024 ? '7' : '7';
  var captionDataY = screenWidth > 1024 ? '107' : '380';

  //console.log('captionWidth' + captionWidth);

  async function sendEmail() {
    const response = await fetch(sendEmailAddress, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        fromEmail: email,
        subject: name,
        message: message
      })
    });
    return response;
  }

  const handleSubmit = async (e) => {

    try {
      console.log("email is " + email);

      const response = await sendEmail().catch((error) => {
        console.error("Error sending email:", error);
        setUserMessage("Error sending email");
      });

      if (response.status === 200) {
        setUserMessage("Thanks for contacting us, we will review your message and get back to you soon");
      }
      else {
        setUserMessage("Error sending message");
      }
    }
    catch (error) {
      setUserMessage("Error sending message");
    }
  }


  return (
    <div data-type-of-widget="2" className="home page kids-front-page t-pattern-1">

      <div className="top-panel">
        <div className="l-page-width clearfix">
          <div className="wrapper">
            <div className="widget widget_cws_tweets">
              <div className='cws-widget-content '>
                <div className="latest_tweets ">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="kids-bg-level-1">
        <div className="bg-level-1"></div>
        <header id="kids_header">
          <div className="l-page-width clearfix">
            <ul className="kids_social">

            </ul>


            <div id="kids_logo_block" className="logo-position-left">
              <a id="kids_logo_text" href="index.html"><img src="images/KidstorytaLogo.png" alt="Kidstoryta" title="Kidstoryta Logo" /></a>
            </div>
            <nav id="kids_main_nav" className="menu-position-right" >

              <ul id="menu-main" className="clearfix flexnav " data-breakpoint="800">
                <li className="menu-item "><RouterLink to="/Home">Home</RouterLink>
                </li>
                <li className="menu-item "><RouterLink to="/Plans">Plans</RouterLink>
                </li>

                <li className="menu-item "><RouterLink to="/register">Free Trial</RouterLink>
                </li>

                <li className="menu-item"><RouterLink to="/Login">Login</RouterLink>
                </li>
              </ul>
            </nav>

          </div>

        </header>
      </div>

      <div id="kids_middle_container">

        <div className="bg-level-2-full-width-container kids_bottom_content">
          <div className="bg-level-2-page-width-container no-padding">
            <section className="kids_bottom_content_container">
              <div className="page-content">
                <div className="container l-page-width">
                  <div className="entry-container ">
                    <main>
                      <div className='grid-row clearfix'>
                        <div className='grid-col grid-col-12'>
                          <section className='cws-widget'>
                            <div id="about-section" className='widget-title'>Privacy Policy</div>
                            <section className='cws_widget_content'>
                              <p>
                                Kidstoryta™ ("we," "us," or "our") respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose your personal information when you use the Kidstoryta™ website/application (the "Service").

                                By using the Service, you agree to the collection and use of your personal information in accordance with this Privacy Policy.

                                <br /><br /><h3>1. Information We Collect</h3>
                                1.1 Personal Information: When you register for the Service, we collect certain personal information, including your first name, last name, and email address. This information is used to manage your account, provide the Service, and communicate with you.
                                <br /><br />1.2 Payment Information: We use a third-party payment processor, Stripe, to handle all payment transactions. When you make a payment, your credit card information is processed and stored by Stripe, not by us.
                                <br /><br /><h3>2. Use of Your Information</h3>
                                2.1 We use your personal information to provide the Service, manage your subscription, communicate with you, and improve the Service.
                                <br /><br />2.2 We do not sell, rent, or share your personal information with third parties for their marketing purposes.
                                <br /><br /><h3>3. Third-Party Services</h3>
                                3.1 The Service integrates with Stripe, a third-party payment processor. Your use of Stripe is subject to their own terms and privacy policy. We are not responsible for how Stripe collects, uses, or discloses your personal information.
                                <br /><br /><h3>4. Security</h3>
                                4.1 We use reasonable administrative, technical, and physical measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
                                <br /><br /><h3>5. Your Rights</h3>
                                5.1 You may access, update, or delete your personal information at any time through your account settings.
                                <br /><br />5.2 If you have any questions about your personal information or this Privacy Policy, please contact us at support@kidstorya.com
                                <br /><br /><h3>6. Changes to This Privacy Policy</h3>
                                6.1 We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated Privacy Policy on the Service. Your continued use of the Service after any changes to the Privacy Policy indicates your acceptance of the updated Privacy Policy.


                              </p>
                            </section>
                          </section>
                        </div>
                      </div>

                      <div className='grid-row clearfix margin-top-none margin-bottom-none'>
                        <div className='grid-col grid-col-12'>
                          <section className='cws-widget'>
                            <section className='cws_widget_content'>
                              <hr />
                              <p>&nbsp;</p>
                            </section>
                          </section>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>

            </section>

          </div>
          <div className="content_bottom_bg"></div>
        </div>
      </div>

      <div className="kids-footer-copyrights footer">
        <div className="l-page-width  clearfix">
          <div className="wrapper">

            <div className="widget widget_text">
              <div className="textwidget">Copyrights @2023: Kidstoryta™</div>
            </div>
          </div>
        </div>
        <div className="dark-mask"></div>
      </div>
    </div>
  );
};

export default TermsPage;
