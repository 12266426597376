import React from 'react';
import book from "../src/images/book.png";
import './dashboard.css';
import KisStory from "./images/create-story.png";
import kLogo from "./images/k-logo.png";
import plus from "./images/plus.png";
import storyImage from "./images/story-image.png";

const Dashboard = () => {
    return (
        <div className='dashboard-container'>
            <div className="form-logo-section">
                <img src={KisStory} alt="story-book" />
            </div>
            <h1 className='dashboard-title-head'>Your Stories</h1>
            <div className='stories-card'>
                <div className='create-story-card'>
                    <div>
                        <img src={plus} alt="plus" />
                    </div>
                    <p>Create a new <br />story</p>
                </div>
                {[1, 2, 3, 4].map((_, index) => (<div className='story-cards' key={index}>
                    <img src={storyImage} alt="card-image" />
                    <p>Story title go here</p>
                </div>))}
            </div>
            <div className="top-right-btn">
                <div className="generate-story">
                    <button type="submit">
                        <img src={book} width="45" height="35" alt="book" />
                        <span>Generate Story</span>
                    </button>
                </div>
            </div>
            <div className="top-logo">
                <img src={kLogo} alt="logo" width="100px" height="40px" />
            </div>
        </div >
    )
}

export default Dashboard