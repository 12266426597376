import React, { useState, useEffect } from 'react';
import './images/favicon.png';
import './css/font-awesome.css';
import './css/styles.css';
import './css/maps.css';
import './css/woocommerce.css';
//import './css/flexnav.css';
import './css/prettyPhoto.css';
import './revslider/styles.css';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import { Auth, Amplify } from "aws-amplify";
import Slideshow from './Slideshow';

const TermsPage = () => {

  const awsconfig = {
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_3uYXEhMon',
      userPoolWebClientId: '1g6jutokcivrebiu2tf56fpj11'
    },
    credentials: {
      accessKeyId: 'AKIARUIO25LGPG655F7N',
      secretAccessKey: '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'
    }
  };

  Auth.configure(awsconfig);

  const sendEmailAddress = "https://hdbvjflnhfp6vvvdditgfgsruq0futml.lambda-url.us-east-2.on.aws/";

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");

  var screenWidth = window.innerWidth;
  // Set the startwidth and startheight based on the screen width
  var location = screenWidth > 1024 ? 'center right' : 'center top';
  var captionWidth = screenWidth > 1024 ? '45%' : '97%';
  var titleDataX = screenWidth > 1024 ? '7' : '30';
  var titleDataY = screenWidth > 1024 ? '50' : '320';
  var captionDataX = screenWidth > 1024 ? '7' : '7';
  var captionDataY = screenWidth > 1024 ? '107' : '380';

  //console.log('captionWidth' + captionWidth);

  async function sendEmail() {
    const response = await fetch(sendEmailAddress, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        fromEmail: email,
        subject: name,
        message: message
      })
    });
    return response;
  }

  const handleSubmit = async (e) => {

    try {
      console.log("email is " + email);

      const response = await sendEmail().catch((error) => {
        console.error("Error sending email:", error);
        setUserMessage("Error sending email");
      });

      if (response.status === 200) {
        setUserMessage("Thanks for contacting us, we will review your message and get back to you soon");
      }
      else {
        setUserMessage("Error sending message");
      }
    }
    catch (error) {
      setUserMessage("Error sending message");
    }
  }


  return (
    <div data-type-of-widget="2" className="home page kids-front-page t-pattern-1">

      <div className="top-panel">
        <div className="l-page-width clearfix">
          <div className="wrapper">
            <div className="widget widget_cws_tweets">
              <div className='cws-widget-content '>
                <div className="latest_tweets ">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="kids-bg-level-1">
        <div className="bg-level-1"></div>
        <header id="kids_header">
          <div className="l-page-width clearfix">
            <ul className="kids_social">

            </ul>


            <div id="kids_logo_block" className="logo-position-left">
              <a id="kids_logo_text" href="index.html"><img src="images/KidstorytaLogo.png" alt="Kidstoryta" title="Kidstoryta Logo" /></a>
            </div>
            <nav id="kids_main_nav" className="menu-position-right" >

              <ul id="menu-main" className="clearfix flexnav " data-breakpoint="800">
                <li className="menu-item "><RouterLink to="/Home">Home</RouterLink>
                </li>
                <li className="menu-item "><RouterLink to="/Plans">Plans</RouterLink>
                </li>

                <li className="menu-item "><RouterLink to="/register">Free Trial</RouterLink>
                </li>

                <li className="menu-item"><RouterLink to="/Login">Login</RouterLink>
                </li>
              </ul>
            </nav>

          </div>

        </header>
      </div>

      <div id="kids_middle_container">

        <div className="bg-level-2-full-width-container kids_bottom_content">
          <div className="bg-level-2-page-width-container no-padding">
            <section className="kids_bottom_content_container">
              <div className="page-content">
                <div className="container l-page-width">
                  <div className="entry-container ">
                    <main>
                      <div className='grid-row clearfix'>
                        <div className='grid-col grid-col-12'>
                          <section className='cws-widget'>
                            <div id="about-section" className='widget-title'>Terms and Conditions</div>
                            <section className='cws_widget_content'>
                              <p>
                                <h3>1. Introduction</h3>

                                Welcome to the " Kidstoryta™" website/application (the "Service"). The Service is owned and operated by Kidstoryta™ ("we," "us," or "our"). By accessing or using the Service, you ("User" or "you") agree to be bound by these Terms and Conditions ("Terms"). If you do not agree to these Terms, please do not use the Service.

                                <br /><br />1.1. The Service provides a subscription-based platform that uses generative AI to create stories tailored for children.

                                <br /><br />1.2. We reserve the right to modify these Terms at any time. We will notify you of any material changes by posting the updated Terms on the Service. Your continued use of the Service after any changes to the Terms indicates your acceptance of the updated Terms.

                                <br /><br /><h3>2. Eligibility</h3>

                                2.1. To use the Service, you must be at least 18 years old or have the consent of a parent or legal guardian who is at least 18 years old. By using the Service, you represent and warrant that you meet these eligibility requirements.

                                <br /><br /><h3>3. Subscription</h3>

                                3.1. The Service is available through a paid monthly subscription. You can review the available subscription plans and pricing on our "Pricing" page.

                                <br /><br />3.2. All payments are processed by our third-party payment processor. By subscribing to the Service, you agree to provide accurate and complete payment information and authorize the payment processor to charge the applicable fees.

                                <br /><br />3.3. Your subscription will automatically renew each month unless you cancel it before the end of the current billing period. You can cancel your subscription at any time through your account settings. No refunds will be provided for partially used subscription periods.

                                <br /><br /><h3>4. Use of the Service</h3>

                                4.1. You may use the Service only for personal, non-commercial purposes, and you agree to comply with all applicable laws and regulations in your use of the Service.

                                <br /><br />4.2. You are responsible for any content generated by the AI for you, including but not limited to stories, characters, and illustrations. You agree not to use the Service to generate content that is offensive, inappropriate, or harmful to children.

                                <br /><br />4.3. We reserve the right to remove any content that we deem to violate these Terms or any applicable laws, and we may terminate your access to the Service if we determine that you have violated these Terms.

                                <br /><br /><h3>5. Intellectual Property</h3>

                                5.1. The content generated by the AI, including stories, characters, and illustrations, is owned by Kidstoryta™. You are granted a non-exclusive, non-transferable, revocable license to access and use the content for personal, non-commercial purposes.

                                <br /><br />5.2. The Service and its underlying technology, including the AI algorithms, are protected by copyright, trademark, and other intellectual property laws. Unauthorized use of the Service or its content may result in a violation of these laws.

                                <br /><br /><h3>6. Limitation of Liability</h3>

                                6.1. The Service is provided "as is" without any warranties of any kind, either express or implied. We do not guarantee the accuracy, quality, or suitability of the content generated by the AI for any particular purpose.

                                <br /><br />6.2. In no event shall we be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Service or these Terms.

                                <br /><br /><h3>7. Indemnification</h3>

                                7.1. You agree to indemnify, defend, and hold harmless Kidstoryta™ and its affiliates, directors, officers, employees, and agents from and against any and all claims, damages, losses, costs, and expenses, including reasonable attorneys' fees, arising from or related to your use of the Service or your breach of these

                                <br /><br /><h3>8. Third-Party Services</h3>

                                8.1. The Service may integrate with third-party services, such as payment processors like Stripe. Your use of these third-party services is governed by their respective terms and conditions. We are not responsible for the operation, availability, or security of any third-party services, including any failures or disruptions in their services.

                                <br /><br />8.2. Any issues or errors relating to third-party services, such as Stripe, should be reported directly to the provider of that service. We are not responsible for resolving such issues or errors.

                                <br /><br /><h3>9. Privacy</h3>

                                9.1. Your use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy to understand how we collect, use, and share your personal information.

                                <br /><br /><h3>10. Dispute Resolution</h3>

                                10.1. If a dispute arises between you and Kidstoryta™ related to your use of the Service or these Terms, the dispute will be resolved in accordance with the laws of the Texas, USA, without regard to its conflict of law principles.

                                <br /><br />10.2. You agree to submit to the exclusive jurisdiction of the courts located in Texas, USA for the resolution of any disputes arising from these Terms or your use of the Service.

                                <br /><br /><h3>11. Contact Information</h3>

                                11.1. If you have any questions about these Terms or the Service, please contact us at [Company Email Address].

                                <br /><br />By using the Service, you acknowledge that you have read and agreed to these Terms and Conditions.

                                <br /><br /><h3>12. Data Collection and Storage</h3>

                                12.1. As part of providing the Service, we collect and store certain personal information, including your first name, last name, and email address. We use this information to manage your subscription, provide customer support, and send you important notifications about the Service.

                                <br /><br />12.2. We do not store your credit card information. All payment transactions are processed by our third-party payment provider, Stripe, in accordance with their Privacy Policy and Terms of Service. We have no control over, and are not responsible for, Stripe's processing of your credit card information.

                                <br /><br />12.3. We take reasonable steps to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.

                                <br /><br />12.4. By using the Service, you consent to our collection, use, and storage of your personal information as described in these Terms and our Privacy Policy.
                                Remember, the Privacy Policy should go into more detail about the types of data collected, how it's used, who it's shared with, and how users can manage their data. It's also important to comply with all applicable data protection laws when handling personal information.



                              </p>
                            </section>
                          </section>
                        </div>
                      </div>

                      <div className='grid-row clearfix margin-top-none margin-bottom-none'>
                        <div className='grid-col grid-col-12'>
                          <section className='cws-widget'>
                            <section className='cws_widget_content'>
                              <hr />
                              <p>&nbsp;</p>
                            </section>
                          </section>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>

            </section>

          </div>
          <div className="content_bottom_bg"></div>
        </div>
      </div>

      <div className="kids-footer-copyrights footer">
        <div className="l-page-width  clearfix">
          <div className="wrapper">

            <div className="widget widget_text">
              <div className="textwidget">Copyrights @2023: Kidstoryta™</div>
            </div>
          </div>
        </div>
        <div className="dark-mask"></div>
      </div>
    </div>
  );
};

export default TermsPage;
