import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import './Login.css';
import KisStory from "./images/create-story.png";

const Help = ({ email, setEmail }) => {

  const awsconfig = {
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_3uYXEhMon',
      userPoolWebClientId: '1g6jutokcivrebiu2tf56fpj11'
    },
    credentials: {
      accessKeyId: 'AKIARUIO25LGPG655F7N',
      secretAccessKey: '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'
    }
  };

  //alert("inside login");

  //Amplify.configure(awsconfig);

  Auth.configure(awsconfig);

  const navigate = useNavigate();

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [userMessage, setUserMessage] = useState("");

  const sendEmailAddress = "https://hdbvjflnhfp6vvvdditgfgsruq0futml.lambda-url.us-east-2.on.aws/";

  useEffect(() => {

    async function getCurrentUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log("Logged in user email is: " + user.attributes.email);
        setEmail(user.attributes.email);
      } catch (error) {
        console.log("error getting current user from Cognito, let's check google " + error);
        //check if user authenticated thru google
        const googleEmail = localStorage.getItem('kidstoryta_email');
        if (googleEmail) {
          setEmail(googleEmail);
        }
        else {
          navigate('/Login');
        }
      }
    }
    getCurrentUser();
  }, []);

  async function sendEmail() {
    const response = await fetch(sendEmailAddress, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        fromEmail: email,
        subject: subject,
        message: message
      })
    });
    return response;
  }

  const handleSubmit = async (e) => {

    try {
      console.log("email is " + email);

      const response = await sendEmail().catch((error) => {
        console.error("Error sending email:", error);
        setUserMessage("Error sending email");
      });

      if (response.status === 200) {
        setUserMessage("Thanks for contacting us, we will review your message and get back to you soon");
      }
      else {
        setUserMessage("Error sending message");
      }
    }
    catch (error) {
      setUserMessage("Error sending message");
    }
  }

  return (
    <div className="contact-us">
      <div className="form-logo-section">
        <img src={KisStory} alt="story-book" />
      </div>
      <h1 className='help-title-head'>Help</h1>
      <div className='text-div'>
        <p> Need help? Send us a message and our support</p>
        <p> team will get back to you soon</p>
      </div>
      <div className='contact-fields'>
        <div className="field-row">
          <p className='label'>Subject</p>
          <input
            type="text"
            id="subject"
            className='subject-field'
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className="field-row">
          <p className='label'>Message</p>
          <textarea
            type="text"
            id="message"
            rows="15"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            maxLength={700}
          />
        </div>
      </div>
      <div style={{ marginBottom: '10px', color: 'white', fontWeight:600, fontSize:'20px' }}>
        {userMessage}
      </div>
      <div className="help-btn">
        <button id="helpBtn" onClick={handleSubmit}>Submit</button>
      </div>
    </div>

  );

};

export default Help;