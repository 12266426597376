import React, { useState } from 'react';
import './Plans.css';
import kLogo from "./images/k-logo.png";
// Import useNavigate from react-router-dom
import { useNavigate } from 'react-router-dom';
import KisStory from "./images/create-story.png";

const SubscriptionPlan = ({ selectedPlan, setSelectedPlan, annualBilling, setAnnualBilling, userData, setUserData, userExtMessage, setUserExtMessage }) => {

  // Initialize useNavigate
  const navigate = useNavigate();
  const [checkedPlan, setCheckedPlan] = useState(selectedPlan !== '' ? selectedPlan : 'basic');
  const [checkedAnnualBilling, setCheckedAnnualBilling] = useState(annualBilling);
  const [currentAnnualBilling, setCurrentAnnualBilling] = useState(userData.billing_frequency === 'annual' ? true : false);
  const buttonText = "Subscribe";

  const handlePlanChange = (event) => {
    setCheckedPlan(event.target.value);
  };

  const handleAnnualBillingChange = (event) => {
    setCheckedAnnualBilling(!checkedAnnualBilling);
  };

  // Add a function to handle the Subscribe button click
  const handleSubscribeClick = () => {
    setSelectedPlan(checkedPlan);
    setAnnualBilling(checkedAnnualBilling);
    console.log("Selected plan: " + selectedPlan);
    console.log("userData " + userData.email);

    if (userData.email == '') {
      navigate('/register', selectedPlan, annualBilling, userData, setUserData, userExtMessage, setUserExtMessage);
    }
    else {
      navigate('/payment', selectedPlan, annualBilling, userData, setUserData, userExtMessage, setUserExtMessage);
    }

  };

  return (
    <div className="subscription-plan">
      <div className="form-logo-section">
        <img src={KisStory} alt="story-book" />
      </div>
      <h1 className='plan-title-head'>Plans</h1>
      <div className="payment-options">
        <div className="toggle-container" onClick={handleAnnualBillingChange}>
          <div className={`toggle-option monthly ${!checkedAnnualBilling ? 'selected' : ''}`}>Monthly</div>
          <div className={`toggle-option annual ${checkedAnnualBilling ? 'selected' : ''}`}>Annual - Save %20</div>
        </div>
      </div>
      <div className="plan-options">
        <label
          className={checkedPlan === 'basic' ? 'selected-plan' : ''}
          htmlFor="basic"
        >
          <h2 className="plan-title">Basic</h2>
          <p className="plan-price">${checkedAnnualBilling ? '46.99' : '4.99'}/{checkedAnnualBilling ? 'year' : 'month'}</p>
          <p className="plan-stories">{checkedAnnualBilling ? '600' : '50'} stories per {checkedAnnualBilling ? 'year' : 'month'}</p>
          <input
            type="radio"
            id="basic"
            name="plan"
            value="basic"
            checked={checkedPlan === 'basic'}
            onChange={handlePlanChange}
          />
        </label>
        <label
          className={checkedPlan === 'premium' ? 'selected-plan' : ''}
          htmlFor="premium"
        >
          <h2 className="plan-title">Premium</h2>
          <p className="plan-price">${checkedAnnualBilling ? '66.99' : '6.99'}/{checkedAnnualBilling ? 'year' : 'month'}</p>
          <p className="plan-stories">{checkedAnnualBilling ? '1200' : '100'} stories per {checkedAnnualBilling ? 'year' : 'month'}</p>
          <input
            type="radio"
            id="premium"
            name="plan"
            value="premium"
            checked={checkedPlan === 'premium'}
            onChange={handlePlanChange}
          />
        </label>
        <label
          className={checkedPlan === 'gold' ? 'selected-plan' : ''}
          htmlFor="gold"
        >
          <h2 className="plan-title">Gold</h2>
          <p className="plan-price">${checkedAnnualBilling ? '94.99' : '9.99'}/{checkedAnnualBilling ? 'year' : 'month'}</p>
          <p className="plan-stories">{checkedAnnualBilling ? '1800' : '150'} stories per {checkedAnnualBilling ? 'year' : 'month'}</p>
          <input
            type="radio"
            id="gold"
            name="plan"
            value="gold"
            checked={checkedPlan === 'gold'}
            onChange={handlePlanChange}
          />
        </label>
      </div>
      <p></p>
      <p className='plan-text'>
        {checkedPlan === userData.plan && checkedAnnualBilling === currentAnnualBilling && userData.plan !== '' ? 'This is your current plan' : 'You have selected the ' + checkedPlan + (checkedAnnualBilling ? ' yearly' : ' monthly') + ' plan'}
      </p>
      <div className="register-btn">
        <button id="registerBtn" onClick={handleSubscribeClick} disabled={checkedPlan === userData.plan && checkedAnnualBilling === currentAnnualBilling}>{buttonText}</button>
      </div>
      {/* <div className="top-logo">
        <img src={kLogo} alt="logo" width="100px" height="40px" />
      </div> */}
    </div>
  );
};

export default SubscriptionPlan;
