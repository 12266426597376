import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import KisStory from "./images/create-story.png";
import Google from './images/Google.png';
import './register.css';


const RegisterPage = ({ selectedPlan, annualBilling, userData, setUserData, userExtMessage, setUserExtMessage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState(location.state?.existingPassword || '');
  const [email, setEmail] = useState(location.state?.existingEmail || '');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [showConfirmationForm, setShowConfirmationForm] = useState(location.state?.existingConfirmation || false);
  const [errorMessage, setErrorMessage] = useState('');


  const addUserProfileAddress = "https://wx7dlc2wezs7g47myix66g3k3m0wymxf.lambda-url.us-east-2.on.aws";

  const awsconfig = {
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_3uYXEhMon',
      userPoolWebClientId: '1g6jutokcivrebiu2tf56fpj11',
      oauth: {
        domain: 'kidstoryta.auth.us-east-2.amazoncognito.com',
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: 'https://app.kidstoryta.ai/oauth2callback',
        redirectSignOut: 'https://app.kidstoryta.ai',
        responseType: 'code'
      }
    },
    credentials: {
      accessKeyId: 'AKIARUIO25LGPG655F7N',
      secretAccessKey: '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'
    }
  };

  //alert("inside register");

  //Amplify.configure(awsconfig);

  Auth.configure(awsconfig);

  const handleGoogleSignIn = async () => {
    try {
      const user = await Auth.federatedSignIn({ provider: 'Google' });
      console.log('Google sign-in successful:', user);
      // Perform any additional logic or redirection as needed
    } catch (error) {
      console.error('Google sign-in error:', error);
      // Handle the sign-in error
    }
  };

  const handleGoogleSignInCustom = (event) => {
    event.preventDefault();
    window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?' +
      'response_type=code' +
      '&client_id=540130539757-4euhj1snjiq8tupvntfbgbv53qucpl02.apps.googleusercontent.com' +
      '&redirect_uri=https://app.kidstoryta.ai/oauth2callback' +
      '&scope=email profile openid';
  }


  async function addUserProfile() {
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

    //const stripe = require('stripe')('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');

    //const customer = await stripe.customers.create({
    //  email: email
    // });

    //console.log("customer created " + customer.id);

    const response = await fetch(addUserProfileAddress, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        active: "true",
        first_name: firstName,
        last_name: lastName,
        join_date: localISOTime,
        last_billing_date: '',
        plan: '',
        plan_start_date: '',
        story_balance: 5,
        billing_customer_id: 'temp',
        billing_frequency: '',
        cancelation_reason: ''
      })
    });
    return response;
  }

  const resendCode = async () => {
    try {
      await Auth.resendSignUp(email);
      console.log('Confirmation code resent successfully');
      setErrorMessage("A new confirmation code was sent");
    } catch (error) {
      console.log(error);
      console.log('error resending code:', error);
      setErrorMessage(error.message);
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    if (!firstName || !email || !password) {
      console.log("required fields empty");
      setErrorMessage("Please fill out all required fields.");
      return;
    }
    // Show spinner and disable button
    document.getElementById('registerSpinner').style.display = "inline-block";
    document.getElementById('register_Text').style.display = "none";
    document.getElementById('registerBtn').disabled = true;

    var username = email;
    try {
      setErrorMessage(''); // Clear the error message
      console.log("before sign up");
      const user = await Auth.signUp({
        username,
        password,
        attributes: {
          email
        },
      });
      document.getElementById('register_Text').style.display = "inline-block";
      console.log('user:', user);
      //setShowConfirmationForm(true);
      handleConfirmationSubmit();
      // Hide spinner and enable button

    } catch (error) {
      console.log('error signing up:', error);
      setErrorMessage(error.message);
      // Hide spinner and enable button
      document.getElementById('registerSpinner').style.display = 'none';
      document.getElementById('register_Text').style.display = "inline-block";
      document.getElementById('registerBtn').disabled = false;
    }
  }


  async function handleConfirmationSubmit(event) {
    //event.preventDefault();
    // Show spinner and disable button
    //document.getElementById('confirmSpinner').style.display = "inline-block";
    //document.getElementById('confirmBtn').disabled = true;

    try {
      //console.log(email);
      //console.log(verificationCode);
      //await Auth.confirmSignUp(email, verificationCode);
      console.log('user confirmed');
      const response = await addUserProfile();

      if (response !== null && response.status === 200) {
        console.log('user profile added to DB');
        await Auth.signIn(email, password);
        console.log("sign in successful");
        setEmail(email);
        //setUserData({ ...userData, email: email, first_name: firstName, last_name: lastName });
        //console.log("selectedplan in register2 is " + selectedPlan);
        //navigate('/payment', selectedPlan, annualBilling, userData, setUserData, userExtMessage, setUserExtMessage);
        navigate('/');
      }
      else {
        setErrorMessage("There was an issue with registration, please try again later");
        // TODO navigater to the login page
        await Auth.signIn(email, password);
        const deletedUser = await Auth.currentAuthenticatedUser();
        await Auth.deleteUser(deletedUser);
        console.log('User deleted from Cognito');
        //navigate('/Login');
      }

      // Hide spinner and enable button
      //document.getElementById('confirmSpinner').style.display = 'none';
      //document.getElementById('confirmBtn').disabled = false;

      document.getElementById('registerSpinner').style.display = 'none';
      document.getElementById('registerBtn').disabled = false;

    } catch (error) {
      console.log('error confirming sign up or signing in:', error);
      setErrorMessage(error.message);
      // Hide spinner and enable button
      //document.getElementById('confirmSpinner').style.display = 'none';
      //document.getElementById('confirmBtn').disabled = false;

      document.getElementById('registerSpinner').style.display = 'none';
      document.getElementById('registerBtn').disabled = false;
    }
  }

  return (
    <div className='register-container'>
      <div className='container'>
        <div className="form-logo-section">
          <img src={KisStory} alt="story-book" />
        </div>
        <h1 className='heading'>Register</h1>
        <div className='google-btn'>
          <button class="rounded-button" onClick={handleGoogleSignInCustom}>
            <img src={Google} alt="Google Logo" class="google-logo" />
            <span class="button-text">Continue with Google</span>
          </button>
        </div>
        <p className='or'>OR</p>
        <form className='register-form' onSubmit={handleSubmit}>
          <div className='names'>
            <input type='text' placeholder='First Name*' value={firstName}
              onChange={(e) => setFirstName(e.target.value)} />
            <input type='text' placeholder='Last Name' value={lastName}
              onChange={(e) => setLastName(e.target.value)} />
          </div>
          <div className='email'>
            <input type='email' placeholder='Email*' value={email}
              onChange={(event) => setEmail(event.target.value)} />
          </div>
          <div className='password'>
            <input type='password' placeholder='Password*' value={password}
              onChange={(event) => setPassword(event.target.value)} />
          </div>
          <div className="register-btn">
            <button type="submit" id="registerBtn">
              <span style={{ display: 'inline-block' }} id='register_Text'> Register </span>
              <span id="registerSpinner" style={{ position: 'absolute', top: '54%', left: '50%', transform: 'translate(-50%, -50%)', display: 'none' }}>
                <i className="fa fa-spinner fa-spin"></i>
              </span>
            </button>
          </div>
          {errorMessage && (
            <div style={{ width: '100%', color: 'red', textAlign: 'center' }} className="error-message">{errorMessage}</div>
          )}
          <p className='term-conditions'>By signing up you accept our<RouterLink to="https://kidstoryta.ai/terms-and-conditions/"><span style={{ color: "#f2df9d" }}>Terms and Conditions</span></RouterLink></p>
        </form>
      </div>
      {/* <div className="top-logo">
        <img src={kLogo} alt="logo" width="100px" height="40px" />
      </div> */}
    </div>
  );
}

export default RegisterPage;
