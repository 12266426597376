import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import './Login.css';
import KisStory from "./images/create-story.png";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [stage, setStage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSendCode = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    try {
      await Auth.forgotPassword(email);
      setStage(2);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(email, resetCode, newPassword);
      navigate('/Login');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="login-container" style={{ minHeight: '100vh' }}>
      <div className="form-logo-section">
        <img src={KisStory} alt="story-book" />
      </div>
      <h1 className='forgot-title-head'>Forgot Password</h1>
      {stage === 1 && (
        <form onSubmit={handleSendCode} className="forgot-form">
          <div className="forgot-field">
            <h4 style={{textAlign:'end'}}>Email</h4>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="forgot-btn">
            <button id="forgotBtn" type="submit">
              Send Reset Code
              <span id="registerSpinner" style={{ position: 'absolute', top: '67%', left: '50%', transform: 'translate(-50%, -50%)', display: 'none' }}>
                <i className="fa fa-spinner fa-spin"></i>
              </span>
            </button>
          </div>
        </form>
      )}
      {stage === 2 && (
        <form onSubmit={handleResetPassword} className="forgot-form">
          <div className="forgot-field">
            <h4>Reset Code</h4>
            <input
              type="text"
              id="resetCode"
              value={resetCode}
              onChange={(e) => setResetCode(e.target.value)}
            />
          </div>
          <div className="forgot-field">
            <h4>New Password:</h4>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="forgot-field">
            <h4>Confirm New Password:</h4>
            <input
              type="password"
              id="confirmNewPassword"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </div>
          <div className="forgot-btn">
            <button id="forgotBtn" type="submit">
              Reset Password
              <span id="registerSpinner" style={{ position: 'absolute', top: '67%', left: '50%', transform: 'translate(-50%, -50%)', display: 'none' }}>
                <i className="fa fa-spinner fa-spin"></i>
              </span>
            </button>
          </div>
        </form>
      )}
      {/* <div className="top-logo">
        <img src={kLogo} alt="logo" width="100px" height="40px" />
      </div> */}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export default ForgotPasswordPage;
