import React, { useState, useEffect } from 'react';
import './Slideshow.css';

const Slideshow = ({ slides, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, interval);

    return () => clearInterval(timer);
  }, [slides.length, interval]);

  const handleArrowClick = (direction) => {
    if (direction === 'left') {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    } else {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }
  };

  return (
    <div className="slideshow">
     {slides.map((slide, index) => (
       <div
         key={index}
         className={`slide ${index === currentIndex ? 'active' : ''}`}
       >
         <div className="slide-text">
           <div className="header-text">{slide.headerText}</div>
           <div className="body-text">
              {slide.bodyText.map((line, i) => (
                       <div key={i} className="body-text-line">
                         <span className="bullet-icon">&#8226;</span> {line}
                       </div>
                     ))}
           </div>
         </div>
         <div
           className="slide-image"
           style={{ backgroundImage: `url(${slide.image})` }}
         />
       </div>
     ))}
      <div className="arrow left" onClick={() => handleArrowClick('left')}>&#10094;</div>
      <div className="arrow right" onClick={() => handleArrowClick('right')}>&#10095;</div>
      <div className="dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Slideshow;
