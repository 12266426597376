export const Voices_Data = [
    { language: "fr-BE", languageName: "French", female: "Lea" },
    { language: "ar-AE", languageName: "Arabic", female: "Hala", male: "Zayd" },
    { language: "nl-BE", languageName: "Belgian Dutch (Flemish)", female: "Lisa" },
    { language: "ca-ES", languageName: "Catalan", female: "Arlet" },
    { language: "yue-CN", languageName: "Chinese (Cantonese)", female: "Hiujin" },
    { language: "da-DK", languageName: "Danish", female: "Sofie" },
    { language: "nl-NL", languageName: "Dutch", female: "Laura" },
    { language: "en-US", languageName: "English (US)", female: ["Joanna", "Kimberly", "Ivy", "Joanna", "Kendra", "Kimberly", "Salli"], male: ["Joey", "Justin", "Kevin", "Matthew", "Ruth", "Stephen"], child: ["Female", "Male"] },
    { language: "fi-FI", languageName: "Finnish", female: "Suvi" },
    { language: "de-DE", languageName: "German", female: "Vicki", male: "Daniel" },
    { language: "hi-IN", languageName: "Hindi", female: "Kajal" },
    { language: "it-IT", languageName: "Italian", female: "Bianca", male: "Adriano" },
    { language: "ja-JP", languageName: "Japanese", female: ["Kazuha", "Tomoko"], male: "Takumi" },
    { language: "ko-KR", languageName: "Korean", female: "Seoyeon" },
    { language: "nb-NO", languageName: "Norwegian", female: "Ida" },
    { language: "pl-PL", languageName: "Polish", female: "Ola" },
    { language: "pt-BR", languageName: "Portuguese (Brazilian)", female: ["Camila", "Vitória/Vitoria"], male: "Thiago" },
    { language: "es-US", languageName: "Spanish (US)", female: "Lupe", male: "Pedro" },
    { language: "sv-SE", languageName: "Swedish", female: "Elin" },
];

export const LanguagesSupported = ["Arabic", "English", "Spanish", "Italian", "German", "Russian", "Korean", "Hebrew", "French"];
