import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import KisStory from "./images/create-story.png";
import './profile.css';

const UserProfile = ({ selectedPlan, setSelectedPlan, annualBilling, setAnnualBilling, userData, setUserData, userExtMessage, setUserExtMessage }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const [firstName, setFirstName] = useState(userData.first_name);
  const [lastName, setLastName] = useState(userData.last_name);

  const [nameOnCard, setNameOnCard] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [userMessage, setUserMessage] = useState(userExtMessage);
  const [paymentMethodId, setPaymentMethodId] = useState('');

  const awsconfig = {
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_3uYXEhMon',
      userPoolWebClientId: '1g6jutokcivrebiu2tf56fpj11'
    },
    credentials: {
      accessKeyId: 'AKIARUIO25LGPG655F7N',
      secretAccessKey: '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'
    }
  };

  // const stripe = require('stripe')('pk_test_51MqTWNFIha26SJqwbgvkRvpFMX4qeN1oEpb4Ee2LuokIOyrKNGAPZ5EoGpo1a6tMSF86zos9lRzyhcu8fWVGNTfD00Hte8pS48');
  const stripe = require('stripe')('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');

  //alert("inside login");

  //Amplify.configure(awsconfig);

  Auth.configure(awsconfig);

  const navigate = useNavigate();

  const subscriptionOptions = [
    { name: 'basic', billing_frequency: 'monthly', label: "Basic - 20 stories a month - $4.99 monthly", story_limit: 20, price: 4.99, price_id: 'price_1N6OBzIcbvaTiSAsUZY6cfCL' },
    { name: 'premium', billing_frequency: 'monthly', label: "Premium - 40 stories a month - $6.99 monthly", story_limit: 40, price: 6.99, price_id: 'price_1N6ODGIcbvaTiSAskm5TEYu1' },
    { name: 'gold', billing_frequency: 'monthly', label: "Gold - 60 stories a month - $9.99 monthly", story_limit: 60, price: 9.99, price_id: 'price_1N6OEzIcbvaTiSAsS1NScDGw' },
    { name: 'basic', billing_frequency: 'annual', label: "Basic - 240 stories a year - $46.99 annual", story_limit: 240, price: 46.99, price_id: 'price_1N3tYmIcbvaTiSAsbHXQbKDD' },
    { name: 'premium', billing_frequency: 'annual', label: "Premium - 480 stories a year - $66.99 annual", story_limit: 480, price: 66.99, price_id: 'price_1N3tYmIcbvaTiSAseX6OADmQ' },
    { name: 'gold', billing_frequency: 'annual', label: "Gold - 720 stories a year - $94.99 annual", story_limit: 720, price: 94.99, price_id: 'price_1N3tYmIcbvaTiSAsovhkK66y' }
  ];

  function handlePaymentSubmit(event) {
    event.preventDefault();
    // Do something with the payment information
  }


  function handleNameOnCardChange(event) {
    setNameOnCard(event.target.value);
    // Do something with the card number
  }

  function handleCardNumberChange(event) {
    setCardNumber(event.target.value);
    // Do something with the card number
  }

  function handleExpirationDateChange(event) {
    setExpirationDate(event.target.value);
    // Do something with the expiration date
  }

  function handleCvvChange(event) {
    setCvv(event.target.value);
    // Do something with the CVV
  }


  function handleCancelSubscription(event) {
    console.log("cancel ");
    navigate('/CancelSubscription', userData, setUserData, userExtMessage, setUserExtMessage);
  }

  function handleChangeSubscription(event) {
    console.log("go to plans ");
    navigate('/Plans', selectedPlan, setSelectedPlan, annualBilling, setAnnualBilling, userData, setUserData, userExtMessage, setUserExtMessage);
  }

  const getUserProfileAddress = "https://cidmp4byjeuct4uqk7lvhgbnja0gdhdc.lambda-url.us-east-2.on.aws/";
  const addUserProfileAddress = "https://wx7dlc2wezs7g47myix66g3k3m0wymxf.lambda-url.us-east-2.on.aws/";

  //if user is not logged in, go to Home page
  useEffect(() => {
    async function getCurrentUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log("Logged in user email is: " + user.attributes.email);
      } catch (error) {
        console.log("error getting current user " + error);
        //check if user authenticated thru google
        const googleEmail = localStorage.getItem('kidstoryta_email');
        if (!googleEmail) {
          navigate('https://kidstoryta.ai/');
        }
      }

    }
    getCurrentUser();
  }, []);

  useEffect(() => {
    async function retrievePaymentMethod() {
      console.log("userData.billing_customer_id " + userData.billing_customer_id);
      if (userData.billing_customer_id !== 'temp') {

        const paymentMethods = await stripe.customers.listPaymentMethods(
          userData.billing_customer_id,
          { type: 'card' }
        );
      
        if (paymentMethods.data.length > 0) {
          setPaymentMethodId(paymentMethods.data[0].id);
          console.log("First payment method " + paymentMethods.data[0].id);
          const paymentMethod = stripe.customers.retrievePaymentMethod(
            userData.billing_customer_id,
            paymentMethods.data[0].id
          );
          setCvv('***');
          setExpirationDate(paymentMethods.data[0].card.exp_month + "/" + paymentMethods.data[0].card.exp_year);
          setCardNumber("**************" + paymentMethods.data[0].card.last4);
          console.log("Card No", paymentMethods.data[0].card)
          console.log("Card No", paymentMethods.data[0])
          setNameOnCard(paymentMethods.data[0].billing_details.name);
        }
      }
    }
    retrievePaymentMethod();
  }, []);

  const updatePaymentMethod = async (e) => {

    if (nameOnCard == '' || cardNumber == '' || expirationDate == '' || cvv == '') {

      setUserMessage('Please fill out all the required fields');
      return;
    }
    if (userData.plan == '') {
      setUserMessage('You must subscribe in a plan first');
      return;
    }

    // Show spinner
    document.getElementById("registerSpinner").style.display = "inline-block";
    document.getElementById('login-text').style.display = "none";

    // Disable button to prevent double-click
    document.getElementById("saveBtn").disabled = true;

    //delete old paymentmethod and add a new one
    try {
      if (userData.billing_customer_id !== 'temp' && cardNumber !== '') {

        const paymentMethod = await stripe.paymentMethods.create({
          type: 'card',
          card: {
            number: cardNumber,
            exp_month: expirationDate.substring(0, 2),
            exp_year: expirationDate.substring(3),
            cvc: cvv,
          },
          billing_details: {
            name: nameOnCard
          }
        });
        console.log("added payment method: " + paymentMethod.id);

        const attach = await stripe.paymentMethods.attach(
          paymentMethod.id,
          { customer: userData.billing_customer_id }
        );

        //detach the old payment method
        await stripe.paymentMethods.detach(paymentMethodId);

        console.log("Detached payment method " + paymentMethodId);

        //set the PaymentMethodId to the new paymentMethod ID
        setPaymentMethodId(paymentMethod.id);

        console.log("attached payment method: " + paymentMethod.id);

        // Hide spinner and enable button when API call is complete
        document.getElementById("registerSpinner").style.display = "none";
        document.getElementById('login-text').style.display = "inline-block";
        document.getElementById("saveBtn").disabled = false;

        setUserMessage("Payment Method was updated successfully");
      }
    }
    catch (error) {
      console.log('error subscribing:', error);
      setUserMessage(error.message);
      // Hide spinner and enable button when API call is complete
      document.getElementById("registerSpinner").style.display = "none";
      document.getElementById('login-text').style.display = "inline-block";
      document.getElementById("saveBtn").disabled = false;
    }
  }

  const updateProfile = async (e) => {
    try {
      const response = await fetch(addUserProfileAddress, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(userData)
      });
      if (response !== null && response.status === 200) {
        setUserMessage("Profile was updated successfully");
      }
      else {
        setUserMessage("There was an issue with updating the profile, please try again later");
      }
    }
    catch (error) {
      setUserMessage("There was an issue with updating the profile, please try again later");
    }

  }
 
  return (
    <div className="profile" style={{ minHeight: '100vh' }} >
      <div className="form-logo-section">
        <img src={KisStory} alt="story-book" />
      </div>
      <h1 className='profile-title-head'>User profile</h1>
      <div className="user-profile">
        <div className="profile-left-side">
          <div className="prfile-data">
            <h5>
              Email
            </h5>
            <p>{userData.email}</p>
          </div>
          <div className="prfile-data">
            <h5>
              Join Date
            </h5>
            <p>{userData.join_date.split('T')[0]}</p>
          </div>
          <div className="prfile-data">
            <h5>
              Last Billing Date
            </h5>
            <p>{userData.last_billing_date.split('T')[0]}</p>
          </div>
        </div>
        <div className="profile-right-side">
          <div className="prfile-data">
            <h5>
              Subscription Plan
            </h5>
            <p>{subscriptionOptions.find(option => option.name === userData.plan && option.billing_frequency === userData.billing_frequency)?.label}</p>
          </div>
          <div className="prfile-data">
            <h5>
              Story Balance
            </h5>
            <p>{userData.story_balance} stories</p>
          </div>
          <div className="prfile-data">
            <h5>
              Payment Method
            </h5>
            <p>Credit Card</p>
          </div>
        </div>
      </div>
      <div className="profile-form">
        <div className="profile-fields">
          <h4>Name on Card</h4>
          <input type="text" className='profile-input' value={nameOnCard} onChange={handleNameOnCardChange}/>
        </div>
        <div className="profile-fields">
          <h4>Credit/Debit Card Number :</h4>
          <input type="text" className='profile-input' value={cardNumber} onChange={handleCardNumberChange}/>
        </div>
        <div className="crad-number">
          <div className="profile-fields">
            <h4>Expiration Date</h4>
            <input type="text" className='profile-input' value={expirationDate} onChange={handleExpirationDateChange}/>
          </div>
          <div className="profile-fields">
            <h4 style={{ minWidth: '50px' }}>CVV</h4>
            <input type="text" className='profile-input' value={cvv} onChange={handleCvvChange}/>
          </div>
        </div>
      </div>
      {userMessage && <div className="error-message">{userMessage}</div>}
      <div className="save-btn">
        <button id="saveBtn" onClick={() => updatePaymentMethod()} style={{ position: 'relative' }}>
          <span id='login-text' style={{ display: 'inline-block' }}>Save</span> 
          <span id="registerSpinner" style={{ position: 'absolute', top: '54%', left: '50%', transform: 'translate(-50%, -50%)', display: 'none' }}>
            <i className="fa fa-spinner fa-spin"></i>
          </span>
        </button>
      </div>
      {/* <table className="table" style={{ maxWidth: '80%' }}>
        <tbody>
          <tr>
            <td colSpan='2' style={{ textAlign: 'center', justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
              <div id="kids_logo_block" >
                <a id="kids_logo_text" href="index.html"><img src="images/KidstorytaLogo.png" alt="Kidstoryta" title="Kidstoryta Logo" /></a>
              </div>
              <p></p>
              <h2>User Profile</h2>
            </td>
          </tr>
          <tr>
            <td style={{ width: "10%" }}><strong>Email:</strong></td>
            <td>{userData.email}</td>
          </tr>
          <tr>
            <td style={{ width: "10%" }} ><strong>Join Date:</strong></td>
            <td>{userData.join_date.split('T')[0]}</td>
          </tr>
          <tr>
            <td style={{ width: "10%" }}><strong>Last Billing Date:</strong></td>
            <td>{userData.last_billing_date.split('T')[0]}</td>
          </tr>
          <tr>
            <td style={{ width: "10%" }}><strong>Subscription Plan:</strong></td>
            <td>
              <div>{subscriptionOptions.find(option => option.name === userData.plan && option.billing_frequency === userData.billing_frequency)?.label}</div>
            </td>
          </tr>
          {userData.plan !== '' && (
            <tr>
              <td style={{ width: "10%" }}>
                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleChangeSubscription}>
                  Change subscription
                </span>
              </td>
              <td>
                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleCancelSubscription}>
                  Cancel subscription
                </span>
              </td>
            </tr>
          )}
          {userData.plan === '' && (
            <tr>
              <td style={{ width: "10%" }}>
                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleChangeSubscription}>
                  Subscribe
                </span>
              </td>
              <td>
              </td>
            </tr>
          )}

          <tr>
            <td style={{ width: "10%" }}><strong>Story Balance:</strong></td>
            <td>{userData.story_balance} stories</td>

          </tr>
          <tr><h2></h2></tr>
          <tr> <strong> Payment Method: </strong></tr>
          <tr>
            <td style={{ width: "50%" }}>Name on Card:</td>
            <td>
              <input type="text" style={{ width: "100%" }} value={nameOnCard} onChange={handleNameOnCardChange} />
            </td>
          </tr>
          <tr>
            <td style={{ width: "10%" }}>Credit/Debit Card Number:</td>
            <td>
              <input type="text" style={{ width: "100%" }} value={cardNumber} onChange={handleCardNumberChange} />
            </td>
          </tr>
          <tr>
            <td style={{ width: "10%" }}>Expiration Date:</td>
            <td>
              <input type="text" style={{ width: "100%" }} value={expirationDate} onChange={handleExpirationDateChange} />
            </td>
          </tr>
          <tr>
            <td style={{ width: "10%" }}>CVV:</td>
            <td>
              <input type="text" style={{ width: "100%" }} value={cvv} onChange={handleCvvChange} />
            </td>
          </tr>
          <tr>
            <td colSpan='2'>
              <div style={{ textAlign: 'center' }}>{userMessage}</div>
            </td>
          </tr>
          <tr>
            <td colSpan='2' style={{ textAlign: 'center' }}>
              <button onClick={() => updatePaymentMethod()} id="saveBtn" style={{ position: 'relative' }}>
                Save
                <span id="spinner" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: 'none' }}>
                  <i className="fa fa-spinner fa-spin"></i>
                </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table> */}
      {/* <div className="top-logo">
        <img src={kLogo} alt="logo" width="100px" height="40px" />
      </div> */}
    </div>


  );

};

export default UserProfile;