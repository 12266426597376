import React, { useState, useEffect } from 'react';
import './images/favicon.png';
import './css/font-awesome.css';
import './css/styles.css';
import './css/maps.css';
import './css/woocommerce.css';
//import './css/flexnav.css';
import './css/prettyPhoto.css';
import './revslider/styles.css';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import { Auth, Amplify } from "aws-amplify";
import Slideshow from './Slideshow';

const HomePage = () => {

  const awsconfig = {
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_3uYXEhMon',
      userPoolWebClientId: '1g6jutokcivrebiu2tf56fpj11'
    },
    credentials: {
      accessKeyId: 'AKIARUIO25LGPG655F7N',
      secretAccessKey: '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'
    }
  };

  Auth.configure(awsconfig);

  const sendEmailAddress = "https://hdbvjflnhfp6vvvdditgfgsruq0futml.lambda-url.us-east-2.on.aws/";

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");

  var screenWidth = window.innerWidth;
  // Set the startwidth and startheight based on the screen width
  var location = screenWidth > 1024 ? 'center right' : 'center top';
  var captionWidth = screenWidth > 1024 ? '45%' : '97%';
  var titleDataX = screenWidth > 1024 ? '7' : '30';
  var titleDataY = screenWidth > 1024 ? '50' : '320';
  var captionDataX = screenWidth > 1024 ? '7' : '7';
  var captionDataY = screenWidth > 1024 ? '107' : '380';

  //console.log('captionWidth' + captionWidth);

  async function sendEmail() {
    const response = await fetch(sendEmailAddress, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        fromEmail: email,
        subject: name,
        message: message
      })
    });
    return response;
  }

  const handleSubmit = async (e) => {

    try {
      console.log("email is " + email);

      const response = await sendEmail().catch((error) => {
        console.error("Error sending email:", error);
        setUserMessage("Error sending email");
      });

      if (response.status === 200) {
        setUserMessage("Thanks for contacting us, we will review your message and get back to you soon");
      }
      else {
        setUserMessage("Error sending message");
      }
    }
    catch (error) {
      setUserMessage("Error sending message");
    }
  }


  const slides = [
    {
      image: 'pic/basketball.png',
      headerText: 'Personalized Stories',
      bodyText: ['Nurture your child\'s passion with our story generator that caters to their favorite hobbies.',
        'Whether they are fascinated by space exploration or enchanted by the world of fairies, our platform crafts tales tailored to their interests.'
      ]
    },
    {
      image: 'pic/honesty.png',
      headerText: 'Value-based Stories',
      bodyText: ['Inspire the next generation with stories that emphasize essential values like honesty and respect.',
        'Our stories seamlessly weave important life lessons into engaging narratives, providing an educational experience that helps children develop strong moral compasses.'
      ]
    },
    {
      image: 'pic/soccer_on_moon.png',
      headerText: 'Unleash kids Imagination',
      bodyText: ['Ignite your child\'s creativity and imagination with our innovative story generator.',
        'Our technology empowers kids to conjure up fantastic worlds, whimsical characters, and thrilling adventures.'
      ]
    },
    {
      image: 'pic/religion.png',
      headerText: 'Faith-based Stories',
      bodyText: ['Strengthen your child\'s spiritual foundation with stories that emphasize religious beliefs and values.',
        'Our story generator offers an inclusive space for families to explore their faith through captivating tales, enriching their spiritual growth.'
      ]
    },
    {
      image: 'pic/istockphoto.jpg',
      headerText: 'Parent-Child Bonding',
      bodyText: [
        'Parents and children collaborate in crafting imaginative tales.',
        'Encourage meaningful conversations and sharing cherished moments.',
        'Inspire young minds and create opportunities for families to bond.'
      ]
    }
  ];


  return (
    <div data-type-of-widget="2" className="home page kids-front-page t-pattern-1">

      <div className="top-panel">
        <div className="l-page-width clearfix">
          <div className="wrapper">
            <div className="widget widget_cws_tweets">
              <div className='cws-widget-content '>
                <div className="latest_tweets ">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="kids-bg-level-1">
        <div className="bg-level-1"></div>
        <header id="kids_header">
          <div className="l-page-width clearfix">
            <ul className="kids_social">

            </ul>


            <div id="kids_logo_block" className="logo-position-left">
              <a id="kids_logo_text" href="index.html"><img src="images/KidstorytaLogo.png" alt="Kidstoryta" title="Kidstoryta Logo" /></a>
            </div>
            <nav id="kids_main_nav" className="menu-position-right" >

              <ul id="menu-main" className="clearfix flexnav " data-breakpoint="800">
                <li className="menu-item">
                  <ScrollLink
                    to="about-section"
                    smooth={true}
                    duration={500}
                  >
                    About
                  </ScrollLink>
                </li>
                <li className="menu-item ">
                  <ScrollLink
                    to="features-section"
                    smooth={true}
                    duration={500}
                  >
                    Features
                  </ScrollLink>
                </li>
                <li className="menu-item">
                  <ScrollLink
                    to="contact-section"
                    smooth={true}
                    duration={500}
                  >
                    Contact
                  </ScrollLink>
                </li>
                <li className="menu-item">
                  <ScrollLink
                    to="testimonials-section"
                    smooth={true}
                    duration={500}
                  >
                    Testimonials
                  </ScrollLink>
                </li>
                <li className="menu-item "><RouterLink to="/Plans">Plans</RouterLink>
                </li>

                <li ><RouterLink style={{ color: 'orange' }} to="/register">Free Trial</RouterLink>
                </li>

                <li className="menu-item"><RouterLink to="/Login">Login</RouterLink>
                </li>
              </ul>
            </nav>

          </div>

        </header>

        <div className="bg-level-2-page-width-container ">
          <div className="bg-level-2 first-part" id="bg-level-2"></div>
          <div className="l-page-width">
            <div className="kids_slider_bg img_slider" >
              <div className="kids_slider_wrapper" >
                <div className="kids_slider_inner_wrapper" >
                  <div className="img-slider" >
                    <div className="App">
                      <Slideshow slides={slides} interval={10000} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div id="kids_middle_container">

        <div className="bg-level-2-full-width-container kids_bottom_content">
          <div className="bg-level-2-page-width-container no-padding">
            <section className="kids_bottom_content_container">
              <div className="page-content">
                <div className="container l-page-width">
                  <div className="entry-container ">
                    <main>
                      <div className='grid-row clearfix'>
                        <div className='grid-col grid-col-12'>
                          <section className='cws-widget'>
                            <div id="about-section" className='widget-title'>About Kidstoryta</div>
                            <section className='cws_widget_content'>
                              <p><img className="alignleft border size-thumbnail" src="pic/about_pic.png" alt="content_img_1" width="150" height="150" />
                                Welcome to Kidstoryta™ (pronounced Kid-Story-ttaa) – the app that brings creativity, imagination, and fun to bedtime stories and other occasions. Our platform was born out of a desire to provide innovative and engaging stories that capture children's attention and spark their imagination. Tired of the same old storybooks, we harnessed the power of AI to create a story generator that produces unique and diverse stories tailored to your child's interests.

                                At Kidstoryta™, we believe in the power of storytelling to help children develop their cognitive and linguistic abilities and foster a lifelong love of reading. Our story generator exposes children to a variety of narratives, promoting creativity, and stimulating their minds.

                                But we're not just about fun and entertainment. Our platform also offers parents the opportunity to create stories that emphasize essential values such as honesty, respect, kindness, empathy, and religious values. By weaving these principles into captivating and memorable stories, parents can use our app to teach important life lessons and promote character development.

                                So, whether you're looking for a magical adventure, a heartwarming tale, or a story that imparts valuable life lessons, Kidstoryta™ is here to provide endless hours of entertainment and learning for both children and parents alike. Join us on this exciting journey and unleash the power of imagination!
                              </p>
                            </section>
                          </section>
                        </div>
                      </div>
                      <div className='grid-row clearfix margin-top-none margin-bottom-none'>
                        <div className='grid-col grid-col-12'>
                          <section className='cws-widget'>
                            <section className='cws_widget_content'>
                              <hr />
                              <p>&nbsp;</p>
                            </section>
                          </section>
                        </div>
                      </div>
                      <div className='grid-row clearfix'>
                        <div className='grid-col grid-col-12'>
                          <section className='cws-widget'>
                            <div id="features-section" className='widget-title'>Features</div>
                            <section class='cws_widget_content'>
                              <h1 style={{ textAlign: 'left' }}>Unique Story Content</h1>
                              <p style={{ textAlign: 'left' }}>With our story generator,
                                no two stories are the same, even if your child selects the same topic.
                                This means your child will never tire of our app's content,
                                and their imagination will be constantly stimulated.</p>
                              <p>&nbsp;</p>
                            </section>
                            <section class='cws_widget_content'>
                              <h1 style={{ textAlign: 'left' }}>Story Image</h1>
                              <p style={{ textAlign: 'left' }}>Each story generated by our app includes a beautiful
                                and engaging image that summarizes the story,
                                bringing it to life in a visual and captivating way.</p>
                              <p>&nbsp;</p>
                            </section>
                            <section class='cws_widget_content'>
                              <h1 style={{ textAlign: 'left' }}>Kid's Age</h1>
                              <p style={{ textAlign: 'left' }}>Our app is designed to cater to a wide range of ages,
                                from toddlers to teenagers. Simply select your child's age
                                , and our story generator will create content
                                that is both engaging and age-appropriate.</p>
                              <p>&nbsp;</p>
                            </section>
                            <section class='cws_widget_content'>
                              <h1 style={{ textAlign: 'left' }}>Story Topic</h1>
                              <p style={{ textAlign: 'left' }}> With our app, users can enter any topic they want to generate
                                a personalized story for their child. From space exploration
                                to fairy tales, our platform creates captivating narratives
                                tailored to their interests.</p>
                              <p>&nbsp;</p>
                            </section>
                            <section class='cws_widget_content'>
                              <h1 style={{ textAlign: 'left' }}>Story Characters</h1>
                              <p style={{ textAlign: 'left' }}>The story characters are fully customizable,
                                so your child can choose their own characters and bring their stories to
                                life in a way that is unique and special to them...</p>
                              <p>&nbsp;</p>
                            </section>
                            <section class='cws_widget_content'>
                              <h1 style={{ textAlign: 'left' }}>Kid-friendly voice</h1>
                              <p style={{ textAlign: 'left' }}>Our app features a warm and friendly voice that narrates
                                each story in a way that kids can easily understand and enjoy.
                                This feature makes the story-telling experience more engaging and immersive,
                                creating a fun and interactive environment for kids to explore their imaginations..</p>
                              <p>&nbsp;</p>
                            </section>
                            <section class='cws_widget_content'>
                              <h1 style={{ textAlign: 'left' }}>Audio Controls</h1>
                              <p style={{ textAlign: 'left' }}>The app features audio controls that allow users to
                                customize their listening experience. These controls include a mute button for times when
                                the user wants to read the story themselves, a pause/resume button that allows the user to
                                take breaks or address other needs while listening, and a stop button that enables the user
                                to restart the story from the beginning.</p>
                              <p>&nbsp;</p>
                            </section>
                          </section>
                        </div>
                      </div>
                      <div className='grid-row clearfix margin-top-none margin-bottom-none'>
                        <div className='grid-col grid-col-12'>
                          <section className='cws-widget'>
                            <section className='cws_widget_content'>
                              <hr />
                              <p>&nbsp;</p>
                            </section>
                          </section>
                        </div>
                      </div>

                      <div className='carousel_header clearfix'>
                        <div id="testimonials-section" className='widget-title'>Testimonials</div>
                      </div>
                      <table className="testimonial-table">
                        <tbody>
                          <tr style={{ border: 'none' }}>
                            <td style={{ border: 'none' }}>
                              <div className="testimonial-wrapper">
                                <div className="testimonial" style={{ marginRight: '10px' }}>
                                  <div>
                                    <p>
                                      Thank you so much for sharing this resource. It is impressive! I already used it with some of the kids, and they loved it! I look forward to implementing it into a lesson </p>
                                  </div>
                                  <div className="author">
                                    Mary - Kindergarten teacher, CA
                                  </div>
                                </div>

                                <div className="testimonial" >
                                  <div>
                                    <p>
                                      This app is amazing! I have been using at home with my kids before bedtime everyday and they love it! No more need to buy more stories or come up with new stories on my own! </p>
                                  </div>
                                  <div className="author">
                                    Jane - Mom, TX </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="testimonial-wrapper">
                                <div className="testimonial" style={{ marginRight: '10px' }}>
                                  <div>
                                    <p>
                                      This app is fantastic for children as it produces stories tailored to their interests, enhances their creativity, boosts their listening and storytelling abilities, and inspires learning. It's both enjoyable and educational for kids, providing personalized stories that capture their attention. My students loved using it!

                                    </p>
                                  </div>
                                  <div className="author">
                                    Katia - Elementary school teacher, PA</div>
                                </div>
                                <div className="testimonial">
                                  <div>
                                    <p>
                                      As a parent, I'm always looking for ways to encourage my child's love for reading, and this app has been a game-changer! The app allows you to input your child's favorite characters and topic, and within seconds, it generates a unique and engaging story.

                                      What's great about this app is that each story is different, so my child is always excited to see what new adventure awaits them. </p>
                                  </div>
                                  <div className="author">
                                    Tom - Dad, CA </div>
                                </div>

                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <section className='cws-widget'>
                        <div id="contact-section" className='widget-title'>Contact Us</div>
                        <section className='cws_widget_content'>
                          <div role='form' className='wpcf7' id='wpcf7-f622-p199-o1' lang='en-US' dir='ltr'>
                            <div className='screen-reader-response'></div>
                            <table style={{ justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
                              <tbody>
                                <tr>
                                  <td style={{ justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
                                    <div style={{ textAlign: 'left', alignItems: 'left', justifyContent: 'left' }}>
                                      <input
                                        type='text'
                                        id='name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                        placeholder='Name'
                                        style={{ width: '100%', height: '40px', fontSize: '16px' }}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
                                    <div style={{ textAlign: 'left', alignItems: 'left', justifyContent: 'left' }}>
                                      <input
                                        type='text'
                                        id='email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        placeholder='E-mail'
                                        style={{ width: '100%', height: '40px', fontSize: '16px' }}
                                      />
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan='2' style={{ justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
                                    <div style={{ textAlign: 'left' }}>
                                      <textarea
                                        style={{ width: '100%', height: '100%', fontSize: '16px', color: '#a2825c' }}
                                        id='message'
                                        rows='10'
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        required
                                        maxLength={500}
                                        placeholder='Message'
                                      ></textarea>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <button onClick={handleSubmit} >Send</button>
                            <div>{userMessage}</div>
                          </div>
                        </section>
                      </section>

                    </main>
                  </div>
                </div>
              </div>

            </section>

          </div>
          <div className="content_bottom_bg"></div>
        </div>
      </div>

      <div className="kids-footer-copyrights footer">
        <div className="l-page-width  clearfix">
          <div className="wrapper">

            <div className="widget widget_text">
              <div className="textwidget">Copyrights @2023: Kidstoryta™<RouterLink to="/Terms">Terms and Conditions</RouterLink><RouterLink to="/Privacy">Privacy Policy</RouterLink></div>
            </div>
          </div>
        </div>
        <div className="dark-mask"></div>
      </div>
    </div>
  );
};

export default HomePage;
