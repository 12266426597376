import { useEffect, useState } from "react";
import { getSpeechMarks, updateMarks } from "./utils/polly";

const ChatMessage = ({ slideText, language, currentTime, isLanguageSupported, voiceId, isPlaying }) => {
    const [text, setText] = useState("");
    const [fullText] = useState(slideText);
    const [marks, setMarks] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [sentences, setSentences] = useState([]);
    const [index, setIndex] = useState(0);

    // useEffect(() => {
    //     if (index < fullText.length) {
    //         setTimeout(() => {
    //             setText(text + fullText[index]);
    //             setIndex(index + 1);
    //         }, 30);
    //     } else setRenderingText(false);
    // }, [index]);

    // Function to highlight text based on audio timestamps

    useEffect(() => {
        async function getMarks() {
            const marks = await getSpeechMarks(slideText, voiceId);

            setMarks(
                updateMarks(
                    marks.filter(({ type }) => type !== "word"),
                    marks.filter(({ type }) => type == "word"),
                    language,
                    marks
                )
            );
        }
        isLanguageSupported && getMarks();
    }, []);

    // console.log(marks);

    useEffect(() => {
        const match = getSpeechMarkAtTime(marks, currentTime);
        setHighlightedIndex(match);
    }, [currentTime]);

    function getSpeechMarkAtTime(speechMarks, time) {
        const length = speechMarks.length;
        let matchedIndex = 0;
        let found = false;
        let i = 1;

        while (i < length && !found) {
            if (speechMarks[i].time <= time) matchedIndex = i;
            else found = true;
            i++;
        }
        return matchedIndex;
    }

    if (!slideText) return null; // Handle the case when slideText is undefined

    return (
        <div className="chat-message">
            <div className="message">
                {language.toLowerCase().includes("english") && (
                    <span className="firstLetterStyle" style={{ background: isPlaying && isLanguageSupported && highlightedIndex === 0 && "#ffcccb" }}>
                        {slideText.charAt(0)}
                    </span>
                )}
                {language.toLowerCase().includes("english")
                    ? slideText
                          .slice(1)
                          .split("\n")
                          .map((item, key) => {
                              return isLanguageSupported ? (
                                  <span key={key} style={{ color: "#0A3D67" }}>
                                      {marks.map((word, index) => (
                                          <span style={{ background: isPlaying && isLanguageSupported && highlightedIndex === index && "#ffcccb" }}>{index === 0 ? word.value.slice(1) : word.value} </span>
                                      ))}
                                      <br />
                                  </span>
                              ) : (
                                  <span key={key} style={{ color: "#0A3D67" }}>
                                      {item.split(" ").map((word, index) => (
                                          <span style={{ background: isPlaying && isLanguageSupported && highlightedIndex === index && "#ffcccb" }}>{word} </span>
                                      ))}
                                      <br />
                                  </span>
                              );
                          })
                    : slideText.split("\n").map((item, key) => {
                          return isLanguageSupported ? (
                              <span key={key} style={{ color: "#0A3D67" }}>
                                  {marks.map((word, index) => (
                                      <span style={{ background: isPlaying && isLanguageSupported && highlightedIndex === index && "#ffcccb" }}>{word.value} </span>
                                  ))}
                                  <br />
                              </span>
                          ) : (
                              <span key={key} style={{ color: "#0A3D67" }}>
                                  {item.split(" ").map((word, index) => (
                                      <span style={{ background: isPlaying && isLanguageSupported && highlightedIndex === index && "#ffcccb" }}>{word} </span>
                                  ))}
                                  <br />
                              </span>
                          );
                      })}
            </div>
        </div>
    );
};

export default ChatMessage;
