import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Auth, Amplify } from "aws-amplify";
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const CancelSubscription = ({ userData, setUserData, userExtMessage, setUserExtMessage }) => {

  const awsconfig = {
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_3uYXEhMon',
      userPoolWebClientId: '1g6jutokcivrebiu2tf56fpj11'
    },
    credentials: {
      accessKeyId: 'AKIARUIO25LGPG655F7N',
      secretAccessKey: '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'
    }
  };

  //alert("inside login");

  //Amplify.configure(awsconfig);

  Auth.configure(awsconfig);

  const navigate = useNavigate();

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [cancelationReason, setCancelationReason] = useState('');
  const stripe = require('stripe')('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');
  const addUserProfileAddress = "https://wx7dlc2wezs7g47myix66g3k3m0wymxf.lambda-url.us-east-2.on.aws/";

  const sendEmailAddress = "https://hdbvjflnhfp6vvvdditgfgsruq0futml.lambda-url.us-east-2.on.aws/";

  const isMountedRef = useRef(false);

  const handleOptionChange = (e) => {
    setCancelationReason(e.target.value);
  }

  const updateProfile = async (e) => {
    try {
      const response = await fetch(addUserProfileAddress, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(userData)
      });
      if (response !== null && response.status === 200) {
        console.log("Profile was updated successfully");
      }
      else {
        console.log("There was an issue with updating the profile, please try again later");
      }
    }
    catch (error) {
      console.log("There was an issue with updating the profile, please try again later");
    }
  }

  useEffect(() => {
    if (userData.plan === '') {
      updateProfile();
      setUserExtMessage("Your subscription for the " + userData.plan + " plan is canceled. You can still use the remaining stories on your account");
      console.log("The userData has been updated to: " + userData.plan);
      navigate('/UserProfile', userData, setUserData, userExtMessage, setUserExtMessage);
    }
  }, [userData.plan]);

  const handleSubmit = async (e) => {

    console.log("billing customer id " + userData.billing_customer_id);
    const response = "";
    const subscription = await stripe.subscriptions.list({
      limit: 1,
      customer: userData.billing_customer_id
    });

    console.log("subscription is " + subscription.data[0].id);

    const deleted = await stripe.subscriptions.del(
      subscription.data[0].id
    );

    console.log("deleted " + deleted);

    const paymentMethods = await stripe.customers.listPaymentMethods(
      userData.billing_customer_id,
      { type: 'card' }
    );

    console.log("First payment method " + paymentMethods.data[0].id);

    const paymentMethod = await stripe.paymentMethods.detach(
      paymentMethods.data[0].id
    );

    setUserData({ ...userData, plan: '', billing_frequency: '', cancelation_reason: cancelationReason });

  }

  return (
    <div className="login-container" >
      <table style={{ maxWidth: '80%', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
        <tbody>
          <tr>
            <td colSpan='2' style={{ textAlign: 'center', justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
              <div id="kids_logo_block" >
                <a id="kids_logo_text" href="index.html"><img src="images/KidstorytaLogo.png" alt="Kidstoryta" title="Kidstoryta Logo" /></a>
              </div>
              <h4 style={{ color: 'black' }}>
                We are sad to see you leave
              </h4>
              <p style={{ color: 'black' }}>
                To help us improve our service, please tell us the reason for canceling your subscription:
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ color: 'black', textAlign: 'left', justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
              <input type="radio" name="reason" value="I Don't use it anymore" onChange={handleOptionChange} style={{ display: 'inline-block', marginRight: '10px' }} />
              I do not use it anymore
            </td>
          </tr>
          <tr>
            <td style={{ color: 'black', textAlign: 'left', justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
              <input type="radio" name="reason" value="It's too expensive" onChange={handleOptionChange} style={{ display: 'inline-block', marginRight: '10px' }} />
              It's too expensive
            </td>
          </tr>
          <tr>
            <td style={{ color: 'black', textAlign: 'left', justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
              <input type="radio" name="reason" value="Other" onChange={handleOptionChange} style={{ display: 'inline-block', marginRight: '10px' }} />
              Other
              <input style={{ margin: '2%', width: '100%', height: '40px' }} type="text" name="otherReason" onChange={handleOptionChange} />
            </td>
          </tr>
        </tbody>
      </table>
      <button onClick={() => handleSubmit()}>
        Cancel Subscription
      </button>
      <div>
        {userMessage}
      </div>
    </div>

  );

};

export default CancelSubscription;