export const AWS_POLLY_SUPPORTED_LANGUAGES =
{
  "Arabic": "arb",
  "Arabic (Gulf)": "ar-AE",
  "Catalan": "ca-ES",
  "Chinese (Cantonese)": "yue-CN",
  "Chinese (Mandarin)": "cmn-CN",
  "Danish": "da-DK",
  "Dutch (Belgian)": "nl-BE",
  "Dutch": "nl-NL",
  "English (Australian)": "en-AU",
  "English (British)": "en-GB",
  "English (Indian)": "en-IN",
  "English (New Zealand)": "en-NZ",
  "English (South African)": "en-ZA",
  "English (US)": "en-US",
  "English (Welsh)": "en-GB-WLS",
  "Finnish": "fi-FI",
  "French": "fr-FR",
  "French (Canadian)": "fr-CA",
  "Hindi": "hi-IN",
  "German": "de-DE",
  "German (Austrian)": "de-AT",
  "Icelandic": "is-IS",
  "Italian": "it-IT",
  "Japanese": "ja-JP",
  "Korean": "ko-KR",
  "Norwegian": "nb-NO",
  "Polish": "pl-PL",
  "Portuguese (Brazilian)": "pt-BR",
  "Portuguese (European)": "pt-PT",
  "Romanian": "ro-RO",
  "Russian": "ru-RU",
  "Spanish (European)": "es-ES",
  "Spanish (Mexican)": "es-MX",
  "Spanish (US)": "es-US",
  "Swedish": "sv-SE",
  "Turkish": "tr-TR",
  "Welsh": "cy-GB"
}

export const AZURE_SUPPORTED_LANGUAGES = {
  "en-US": {
    "language": "English",
    "voices": {
      "female": "en-US-AnaNeural",
      "male": "en-US-MatthewNeural"
    },
    "flag": "🇺🇸"
  },
  "af-ZA": {
    "language": "Afrikaans",
    "voices": {
      "female": "af-ZA-AdriNeural",
      "male": "af-ZA-WillemNeural2"
    },
    "flag": "🇿🇦"
  },
  "am-ET": {
    "language": "Amharic",
    "voices": {
      "female": "am-ET-MekdesNeural",
      "male": "am-ET-AmehaNeural2"
    },
    "flag": "🇪🇹"
  },
  "ar-EG": {
    "language": "Arabic",
    "voices": {
      "female": "ar-EG-SalmaNeural",
      "male": "ar-EG-ShakirNeural"
    },
    "flag": "🇪🇬"
  },
  "az-AZ": {
    "language": "Azerbaijani",
    "voices": {
      "female": "az-AZ-BanuNeural",
      "male": "az-AZ-BabekNeural"
    },
    "flag": "🇦🇿"
  },
  "bg-BG": {
    "language": "Bulgarian",
    "voices": {
      "female": "bg-BG-KalinaNeural",
      "male": "bg-BG-BorislavNeural"
    },
    "flag": "🇧🇬"
  },
  "bn-IN": {
    "language": "Bengali",
    "voices": {
      "female": "bn-IN-TanishaaNeural",
      "male": "bn-IN-RajNeural"
    },
    "flag": "🇮🇳"
  },
  "bn-BD": {
    "language": "Bangla",
    "voices": {
      "female": "bn-BD-NabanitaNeural2",
    },
    "flag": "🇮🇳"
  },
  "bs-BA": {
    "language": "Bosnian",
    "voices": {
      "female": "bs-BA-VesnaNeural",
      "male": "bs-BA-AleksNeural"
    },
    "flag": "🇧🇦"
  },
  "ca-ES": {
    "language": "Catalan",
    "voices": {
      "female": "ca-ES-AlbaNeural",
      "male": "ca-ES-EnricNeural"
    },
    "flag": "🇪🇸"
  },
  "cs-CZ": {
    language: "Czech",
    voices: {
      female: "cs-CZ-VlastaNeural"
    },
    flag: "🇨🇿"
  },
  "cy-GB": {
    language: "Welsh",
    voices: {
      female: "cy-GB-NiaNeural2"
    },
    flag: "🇬🇧"
  },
  "da-DK": {
    "language": "Danish",
    "voices": {
      "female": "da-DK-ChristelNeural",
      "male": "da-DK-ChristianNeural"
    },
    "flag": "🇩🇰"
  },
  "de-DE": {
    "language": "German",
    "voices": {
      "female": "de-DE-KatjaNeural",
      "male": "de-DE-ConradNeural"
    },
    "flag": "🇩🇪"
  },
  "el-GR": {
    "language": "Greek",
    "voices": {
      "female": "el-GR-AthinaNeural",
      "male": "el-GR-StefanosNeural"
    },
    "flag": "🇬🇷"
  },
  "es-ES": {
    language: "Spanish",
    voices: {
      female: "es-ES-ElviraNeural"
    },
    flag: "🇪🇸"
  },
  "et-EE": {
    "language": "Estonian",
    "voices": {
      "female": "et-EE-AnuNeural",
      "male": "et-EE-KertNeural2"
    },
    "flag": "🇪🇪"
  },
  "eu-ES": {
    "language": "Basque",
    "voices": {
      "female": "eu-ES-AinhoaNeural",
      "male": "eu-ES-AnderNeural2"
    },
    "flag": "🇪🇸"
  },
  "fa-IR": {
    language: "Persian",
    voices: {
      female: "fa-IR-DilaraNeural2"
    },
    flag: "🇮🇷"
  },
  "fi-FI": {
    "language": "Finnish",
    "voices": {
      "female": "fi-FI-SelmaNeural",
      "male": "fi-FI-HarriNeural"
    },
    "flag": "🇫🇮"
  },
  "fil-PH": {
    "language": "Filipino",
    "voices": {
      "female": "fil-PH-BlessicaNeural",
      "male": "fil-PH-AngeloNeural2"
    },
    "flag": "🇵🇭"
  },
  "fr-FR": {
    "language": "French",
    "voices": {
      "female": "fr-FR-JosephineNeural",
      "male": "fr-FR-HenriNeural"
    },
    "flag": "🇫🇷"
  },
  "ga-IE": {
    "language": "Irish",
    "voices": {
      "female": "ga-IE-OrlaNeural",
      "male": "ga-IE-ColmNeural2"
    },
    "flag": "🇮🇪"
  },
  "gl-ES": {
    "language": "Galician",
    "voices": {
      "female": "gl-ES-SabelaNeural",
      "male": "gl-ES-RoiNeural2"
    },
    "flag": "🇪🇸"
  },
  "gu-IN": {
    language: "Gujarati",
    voices: {
      female: "gu-IN-DhwaniNeural"
    },
    "flag": "🇮🇳"
  },
  "he-IL": {
    "language": "Hebrew",
    "voices": {
      "female": "he-IL-HilaNeural",
      "male": "he-IL-AmirNeural2"
    },
    "flag": "🇮🇱"
  },
  "hi-IN": {
    "language": "Hindi",
    "voices": {
      "female": "hi-IN-SwaraNeural",
      "male": "hi-IN-PrabhatNeural"
    },
    "flag": "🇮🇳"
  },
  "hr-HR": {
    "language": "Croatian",
    "voices": {
      "female": "hr-HR-GabrijelaNeural",
      "male": "hr-HR-SreckoNeural"
    },
    "flag": "🇭🇷"
  },
  "hu-HU": {
    "language": "Hungarian",
    "voices": {
      "female": "hu-HU-NoemiNeural",
      "male": "hu-HU-TamasNeural"
    },
    "flag": "🇭🇺"
  },
  "hy-AM": {
    "language": "Armenian",
    "voices": {
      "female": "hy-AM-AnahitNeural",
      "male": "hy-AM-HaykNeural2"
    },
    "flag": "🇦🇲"
  },
  "id-ID": {
    "language": "Indonesian",
    "voices": {
      "female": "id-ID-GadisNeural",
      "male": "id-ID-ArdiNeural"
    },
    "flag": "🇮🇩"
  },
  "is-IS": {
    "language": "Icelandic",
    "voices": {
      "female": "is-IS-GudrunNeural",
      "male": "is-IS-GunnarNeural2"
    },
    "flag": "🇮🇸"
  },
  "it-IT": {
    "language": "Italian",
    "voices": {
      "female": "it-IT-ElsaNeural",
      "male": "it-IT-UbertoNeural"
    },
    "flag": "🇮🇹"
  },
  "ja-JP": {
    "language": "Japanese",
    "voices": {
      "female": "ja-JP-NanamiNeural",
      "male": "ja-JP-ShinjiNeural"
    },
    "flag": "🇯🇵"
  },
  "jv-ID": {
    language: "Javanese",
    voices: {
      female: "jv-ID-SitiNeural2"
    },
    flag: "🇮🇩"
  },
  "ka-GE": {
    "language": "Georgian",
    "voices": {
      "female": "ka-GE-EkaNeural",
      "male": "ka-GE-GiorgiNeural2"
    },
    "flag": "🇬🇪"
  },
  "kk-KZ": {
    "language": "Kazakh",
    "voices": {
      "female": "kk-KZ-AigulNeural",
      "male": "kk-KZ-DauletNeural2"
    },
    "flag": "🇰🇿"
  },
  "km-KH": {
    "language": "Khmer",
    "voices": {
      "female": "km-KH-SreymomNeural",
      "male": "km-KH-PisethNeural2"
    },
    "flag": "🇰🇭"
  },
  "kn-IN": {
    "language": "Kannada",
    "voices": {
      "female": "kn-IN-SapnaNeural",
      "male": "kn-IN-GaganNeural2"
    },
    "flag": "🇮🇳"
  },
  "ko-KR": {
    "language": "Korean",
    "voices": {
      "female": "ko-KR-SunHiNeural",
      "male": "ko-KR-YuJinNeural"
    },
    "flag": "🇰🇷"
  },
  "lo-LA": {
    "language": "Lao",
    "voices": {
      "female": "lo-LA-KeomanyNeural",
      "male": "lo-LA-ChanthavongNeural2"
    },
    "flag": "🇱🇦"
  },
  "lt-LT": {
    "language": "Lithuanian",
    "voices": {
      "female": "lt-LT-OnaNeural",
      "male": "lt-LT-LeonasNeural2"
    },
    "flag": "🇱🇹"
  },
  "lv-LV": {
    "language": "Latvian",
    "voices": {
      "female": "lv-LV-EveritaNeural",
      "male": "lv-LV-NilsNeural2"
    },
    "flag": "🇱🇻"
  },
  "mk-MK": {
    "language": "Macedonian",
    "voices": {
      "female": "mk-MK-MarijaNeural",
      "male": "mk-MK-AleksandarNeural2"
    },
    "flag": "🇲🇰"
  },
  "ml-IN": {
    "language": "Malayalam",
    "voices": {
      "female": "ml-IN-SobhanaNeural",
      "male": "ml-IN-MidhunNeural2"
    },
    "flag": "🇮🇳"
  },
  "mn-MN": {
    language: "Mongolian",
    voices: {
      female: "mn-MN-YesuiNeural2"
    },
    flag: '🇲🇳'
  },
  "mr-IN": {
    language: "Marathi",
    voices: {
      female: "mr-IN-AarohiNeural"
    }, "flag": "🇮🇳"
  },
  "ms-MY": {
    "language": "Malay",
    "voices": {
      "female": "ms-MY-YasminNeural",
      "male": "ms-MY-OsmanNeural"
    },
    "flag": "🇲🇾"
  },
  "mt-MT": {
    "language": "Maltese",
    "voices": {
      "female": "mt-MT-GraceNeural",
      "male": "mt-MT-JosephNeural2"
    },
    "flag": "🇲🇹"
  },
  "my-MM": {
    "language": "Burmese",
    "voices": {
      "female": "my-MM-NilarNeural",
      "male": "my-MM-ThihaNeural2"
    },
    "flag": "🇲🇲"
  },
  "nb-NO": {
    language: "Norwegian Bokmål",
    voices: {
      female: "nb-NO-PernilleNeural"
    },
    flag: "🇳🇴"
  },
  "ne-NP": {
    language: "Nepali",
    voices: {
      female: "ne-NP-HemkalaNeural"
    },
    flag: '🇳🇵'
  },
  "nl-NL": {
    "language": "Dutch",
    "voices": {
      "female": "nl-NL-FennaNeural",
      "male": "nl-NL-JanWillemNeural"
    },
    "flag": "🇳🇱"
  },
  "pl-PL": {
    language: "Polish",
    voices: {
      female: "pl-PL-AgnieszkaNeural"
    },
    flag: "🇵🇱"
  },
  "ps-AF": {
    language: 'Pashto',
    voices: {
      female: "ps-AF-LatifaNeural2"
    },
    flag: "🇦🇫"
  },
  "pt-BR": {
    language: "Portuguese",
    voices: {
      female: "pt-BR-FranciscaNeural"
    },
    flag: "🇧🇷"
  },
  "ro-RO": {
    language: "Romanian",
    voices: {
      female: "ro-RO-AlinaNeural"
    }, flag: "🇷🇴"
  },
  "ru-RU": {
    language: "Russian",
    voices: {
      female: "ru-RU-SvetlanaNeural"
    }, flag: "🇷🇺"
  },
  "si-LK": {
    language: "Sinhala",
    voices: {
      female: "si-LK-ThiliniNeural2"
    },
    flag: "🇱🇰"
  },
  "sk-SK": {
    language: "Slovak",
    voices: {
      female: "sk-SK-ViktoriaNeural"
    },
    flag: "🇸🇰"
  },
  'sl-SI': {
    language: "Slovenian",
    voices: {
      female: "sl-SI-PetraNeural"
    },
    flag: "🇸🇮"
  },
  "so-SO": {
    language: "Somali",
    voices: {
      female: "so-SO-UbaxNeural"
    },
    flag: "🇸🇴"
  },
  "sq-AL": {
    "language": "Albanian",
    "voices": {
      "female": "sq-AL-AnilaNeural",
      "male": "sq-AL-IlirNeural2"
    },
    "flag": "🇦🇱"
  },
  "sr-Latn": {
    language: "Serbian",
    voices: {
      female: "sr-Latn-RS-NicholasNeural1"
    },
    flag: "🇷🇸"
  },
  "sr-RS": {
    language: "Serbian",
    voices: {
      female: "sr-RS-SophieNeural2"
    },
    flag: "🇷🇸"
  },
  "su-ID": {
    language: "Sundanese",
    voices: {
      female: "su-ID-TutiNeural2"
    },
    flag: "🇮🇩"
  },
  "sv-SE": {
    language: "Swedish",
    voices: {
      female: "sv-SE-SofieNeural"
    },
    flag: "🇸🇪"
  },
  "ta-IN-": {
    language: "Tamil",
    voices: {
      female: "ta-IN-PallaviNeural"
    },
    "flag": "🇮🇳"
  },
  "te-IN": {
    language: "Telugu",
    voices: {
      female: "te-IN-ShrutiNeural"
    },
    "flag": "🇮🇳"
  },
  "th-TH": {
    language: "Thai",
    voices: {
      female: "th-TH-PremwadeeNeural"
    },
    flag: "🇹🇭"
  },
  "tr-TR": {
    language: "Turkish",
    voices: {
      female: "tr-TR-EmelNeural"
    },
    flag: "🇹🇷"
  },
  "uk-UA": {
    language: "Ukrainian",
    voices: {
      female: "uk-UA-PolinaNeural"
    },
    flag: "🇺🇦"
  },
  "ur-IN": {
    language: "Urdu",
    voices: {
      female: "ur-IN-GulNeural"
    },
    "flag": "🇮🇳"
  },
  "uz-UZ": {
    language: "Uzbek",
    voices: {
      female: "uz-UZ-MadinaNeural2"
    },
    flag: "🇺🇿"
  },
  "vi-VN": {
    language: "Vietnamese",
    voices: {
      female: "vi-VN-HoaiMyNeural"
    },
    flag: "🇻🇳"
  },
  "wuu-CN": {
    "language": "Chinese (Wu, Simplified)",
    "voices": {
      "female": "wuu-CN-XiaotongNeural",
      "male": "wuu-CN-YunzheNeural2"
    },
    "flag": "🇨🇳"
  },
  "yue-CN": {
    "language": "Chinese (Cantonese, Simplified)",
    "voices": {
      "female": "yue-CN-XiaoMinNeural",
      "male": "yue-CN-YunSongNeural1,2"
    },
    "flag": "🇨🇳"
  },
  "zh-CN": {
    "language": "Chinese (Mandarin, Simplified)",
    "voices": {
      "female": "zh-CN-XiaoyouNeural",
      "male": "zh-CN-YunfengNeural"
    },
    "flag": "🇨🇳"
  },
  "zh-HK": {
    "language": "Chinese (Cantonese, Traditional)",
    "voices": {
      "female": "zh-HK-HiuMaanNeural",
      "male": "zh-HK-WingNeural"
    },
    "flag": "🇭🇰"
  },
  "zu-ZA": {
    language: "Zulu",
    voice: {
      female: "zu-ZA-ThandoNeural"
    },
    flag: "🇿🇦"
  },

}
export const AZURE_SUPPORTED_LANGUAGES1 = {
  "af-ZA": {
    "language": "Afrikaans (South Africa)",
    "voices": {
      "female": "af-ZA-AdriNeural",
      "male": "af-ZA-WillemNeural2"
    },
    "flag": "🇿🇦"
  },
  "am-ET": {
    "language": "Amharic (Ethiopia)",
    "voices": {
      "female": "am-ET-MekdesNeural",
      "male": "am-ET-AmehaNeural2"
    },
    "flag": "🇪🇹"
  },
  "ar-AE": {
    "language": "Arabic (United Arab Emirates)",
    "voices": {
      "female": "ar-AE-FatimaNeural",
      "male": "ar-AE-HamdanNeural"
    },
    "flag": "🇦🇪"
  },
  "ar-BH": {
    "language": "Arabic (Bahrain)",
    "voices": {
      "female": "ar-BH-LailaNeural",
      "male": "ar-BH-AliNeural"
    },
    "flag": "🇧🇭"
  },
  "ar-DZ": {
    "language": "Arabic (Algeria)",
    "voices": {
      "female": "ar-DZ-AminaNeural",
      "male": "ar-DZ-IsmaelNeural"
    },
    "flag": "🇩🇿"
  },
  "ar-EG": {
    "language": "Arabic (Egypt)",
    "voices": {
      "female": "ar-EG-SalmaNeural",
      "male": "ar-EG-ShakirNeural"
    },
    "flag": "🇪🇬"
  },
  "ar-IQ": {
    "language": "Arabic (Iraq)",
    "voices": {
      "female": "ar-IQ-RanaNeural",
      "male": "ar-IQ-BasselNeural"
    },
    "flag": "🇮🇶"
  },
  "ar-JO": {
    "language": "Arabic (Jordan)",
    "voices": {
      "female": "ar-JO-SanaNeural",
      "male": "ar-JO-TaimNeural"
    },
    "flag": "🇯🇴"
  },
  "ar-KW": {
    "language": "Arabic (Kuwait)",
    "voices": {
      "female": "ar-KW-NouraNeural",
      "male": "ar-KW-FahedNeural"
    },
    "flag": "🇰🇼"
  },
  "ar-LB": {
    "language": "Arabic (Lebanon)",
    "voices": {
      "female": "ar-LB-LaylaNeural",
      "male": "ar-LB-RamiNeural"
    },
    "flag": "🇱🇧"
  },
  "ar-LY": {
    "language": "Arabic (Libya)",
    "voices": {
      "female": "ar-LY-ImanNeural",
      "male": "ar-LY-OmarNeural"
    },
    "flag": "🇱🇾"
  },
  "ar-MA": {
    "language": "Arabic (Morocco)",
    "voices": {
      "female": "ar-MA-MounaNeural",
      "male": "ar-MA-JamalNeural"
    },
    "flag": "🇲🇦"
  },
  "ar-OM": {
    "language": "Arabic (Oman)",
    "voices": {
      "female": "ar-OM-AyshaNeural",
      "male": "ar-OM-AbdullahNeural"
    },
    "flag": "🇴🇲"
  },
  "ar-QA": {
    "language": "Arabic (Qatar)",
    "voices": {
      "female": "ar-QA-AmalNeural",
      "male": "ar-QA-MoazNeural"
    },
    "flag": "🇶🇦"
  },
  "ar-SA": {
    "language": "Arabic (Saudi Arabia)",
    "voices": {
      "female": "ar-SA-ZariyahNeural",
      "male": "ar-SA-HamedNeural"
    },
    "flag": "🇸🇦"
  },
  "ar-SY": {
    "language": "Arabic (Syria)",
    "voices": {
      "female": "ar-SY-AmanyNeural",
      "male": "ar-SY-LaithNeural"
    },
    "flag": "🇸🇾"
  },
  "ar-TN": {
    "language": "Arabic (Tunisia)",
    "voices": {
      "female": "ar-TN-ReemNeural",
      "male": "ar-TN-HediNeural"
    },
    "flag": "🇹🇳"
  },
  "ar-YE": {
    "language": "Arabic (Yemen)",
    "voices": {
      "female": "ar-YE-MaryamNeural",
      "male": "ar-YE-SalehNeural"
    },
    "flag": "🇾🇪"
  },
  "az-AZ": {
    "language": "Azerbaijani (Latin, Azerbaijan)",
    "voices": {
      "female": "az-AZ-BanuNeural",
      "male": "az-AZ-BabekNeural"
    },
    "flag": "🇦🇿"
  },
  "bg-BG": {
    "language": "Bulgarian (Bulgaria)",
    "voices": {
      "female": "bg-BG-KalinaNeural",
      "male": "bg-BG-BorislavNeural"
    },
    "flag": "🇧🇬"
  },
  "bn-BD": {
    "language": "Bengali (Bangladesh)",
    "voices": {
      "female": "bn-BD-NabanitaNeural",
      "male": "bn-BD-ShafiqNeural"
    },
    "flag": "🇧🇩"
  },
  "bn-IN": {
    "language": "Bengali",
    "voices": {
      "female": "bn-IN-TanishaaNeural",
      "male": "bn-IN-RajNeural"
    },
    "flag": "🇮🇳"
  },
  "bs-BA": {
    "language": "Bosnian (Bosnia and Herzegovina)",
    "voices": {
      "female": "bs-BA-VesnaNeural",
      "male": "bs-BA-AleksNeural"
    },
    "flag": "🇧🇦"
  },
  "ca-ES": {
    "language": "Catalan (Spain)",
    "voices": {
      "female": "ca-ES-AlbaNeural",
      "male": "ca-ES-EnricNeural"
    },
    "flag": "🇪🇸"
  },
  "da-DK": {
    "language": "Danish (Denmark)",
    "voices": {
      "female": "da-DK-ChristelNeural",
      "male": "da-DK-ChristianNeural"
    },
    "flag": "🇩🇰"
  },
  "de-AT": {
    "language": "German (Austria)",
    "voices": {
      "female": "de-AT-IngridNeural",
      "male": "de-AT-JonasNeural"
    },
    "flag": "🇦🇹"
  },
  "de-CH": {
    "language": "German (Switzerland)",
    "voices": {
      "female": "de-CH-LeniNeural",
      "male": "de-CH-JanNeural"
    },
    "flag": "🇨🇭"
  },
  "de-DE": {
    "language": "German (Germany)",
    "voices": {
      "female": "de-DE-MajaNeural",
      "male": "de-DE-ConradNeural"
    },
    "flag": "🇩🇪"
  },
  "el-GR": {
    "language": "Greek (Greece)",
    "voices": {
      "female": "el-GR-AthinaNeural",
      "male": "el-GR-StefanosNeural"
    },
    "flag": "🇬🇷"
  },
  "en-AU": {
    "language": "English (Australia)",
    "voices": {
      "female": "en-AU-KimNeural",
      "male": "en-AU-WilliamNeural"
    },
    "flag": "🇦🇺"
  },
  "en-CA": {
    "language": "English (Canada)",
    "voices": {
      "female": "en-CA-ClaraNeural",
      "male": "en-CA-LiamNeural"
    },
    "flag": "🇨🇦"
  },
  "en-GB": {
    "language": "English (United Kingdom)",
    "voices": {
      "female": "en-GB-SoniaNeural",
      "male": "en-GB-LibbyNeural"
    },
    "flag": "🇬🇧"
  },
  "en-IE": {
    "language": "English (Ireland)",
    "voices": {
      "female": "en-IE-EmilyNeural",
      "male": "en-IE-DarraghNeural"
    },
    "flag": "🇮🇪"
  },
  "en-IN": {
    "language": "English",
    "voices": {
      "female": "en-IN-NeerjaNeural",
      "male": "en-IN-AshwinNeural"
    },
    "flag": "🇮🇳"
  },
  "en-KE": {
    "language": "English (Kenya)",
    "voices": {
      "female": "en-KE-AsiliaNeural",
      "male": "en-KE-BenNeural"
    },
    "flag": "🇰🇪"
  },
  "en-NG": {
    "language": "English (Nigeria)",
    "voices": {
      "female": "en-NG-EzinneNeural",
      "male": "en-NG-JelaniNeural2"
    },
    "flag": "🇳🇬"
  },
  "en-NZ": {
    "language": "English (New Zealand)",
    "voices": {
      "female": "en-NZ-MollyNeural",
      "male": "en-NZ-RachelNeural2"
    },
    "flag": "🇳🇿"
  },
  "en-PH": {
    "language": "English (Philippines)",
    "voices": {
      "female": "en-PH-RosaNeural",
      "male": "en-PH-AngeloNeural2"
    },
    "flag": "🇵🇭"
  },
  "en-SG": {
    "language": "English (Singapore)",
    "voices": {
      "female": "en-SG-LunaNeural",
      "male": "en-SG-AnbuNeural"
    },
    "flag": "🇸🇬"
  },
  "en-TZ": {
    "language": "English (Tanzania)",
    "voices": {
      "female": "en-TZ-ImaniNeural",
      "male": "en-TZ-DaudiNeural"
    },
    "flag": "🇹🇿"
  },
  "en-US": {
    "language": "English",
    "voices": {
      "female": "en-US-AnaNeural",
      "male": "en-US-MatthewNeural"
    },
    "flag": "🇺🇸"
  },
  "en-ZA": {
    "language": "English (South Africa)",
    "voices": {
      "female": "en-ZA-LeahNeural",
      "male": "en-ZA-ThembaNeural"
    },
    "flag": "🇿🇦"
  },
  "et-EE": {
    "language": "Estonian (Estonia)",
    "voices": {
      "female": "et-EE-AnuNeural",
      "male": "et-EE-KertNeural2"
    },
    "flag": "🇪🇪"
  },
  "eu-ES": {
    "language": "Basque",
    "voices": {
      "female": "eu-ES-AinhoaNeural",
      "male": "eu-ES-AnderNeural2"
    },
    "flag": "🇪🇸"
  },
  "fi-FI": {
    "language": "Finnish (Finland)",
    "voices": {
      "female": "fi-FI-SelmaNeural",
      "male": "fi-FI-HarriNeural"
    },
    "flag": "🇫🇮"
  },
  "fil-PH": {
    "language": "Filipino (Philippines)",
    "voices": {
      "female": "fil-PH-BlessicaNeural",
      "male": "fil-PH-AngeloNeural2"
    },
    "flag": "🇵🇭"
  },
  "fr-BE": {
    "language": "French (Belgium)",
    "voices": {
      "female": "fr-BE-CharlineNeural",
      "male": "fr-BE-GerardNeural"
    },
    "flag": "🇧🇪"
  },
  "fr-CA": {
    "language": "French (Canada)",
    "voices": {
      "female": "fr-CA-SylvieNeural",
      "male": "fr-CA-JeanNeural"
    },
    "flag": "🇨🇦"
  },
  "fr-CH": {
    "language": "French (Switzerland)",
    "voices": {
      "female": "fr-CH-ArianeNeural",
      "male": "fr-CH-FabriceNeural"
    },
    "flag": "🇨🇭"
  },
  "fr-FR": {
    "language": "French (France)",
    "voices": {
      "female": "fr-FR-JosephineNeural",
      "male": "fr-FR-HenriNeural"
    },
    "flag": "🇫🇷"
  },
  "ga-IE": {
    "language": "Irish (Ireland)",
    "voices": {
      "female": "ga-IE-OrlaNeural",
      "male": "ga-IE-ColmNeural2"
    },
    "flag": "🇮🇪"
  },
  "gl-ES": {
    "language": "Galician",
    "voices": {
      "female": "gl-ES-SabelaNeural",
      "male": "gl-ES-RoiNeural2"
    },
    "flag": "🇪🇸"
  },
  "he-IL": {
    "language": "Hebrew (Israel)",
    "voices": {
      "female": "he-IL-HilaNeural",
      "male": "he-IL-AmirNeural2"
    },
    "flag": "🇮🇱"
  },
  "hi-IN": {
    "language": "Hindi",
    "voices": {
      "female": "hi-IN-SwaraNeural",
      "male": "hi-IN-PrabhatNeural"
    },
    "flag": "🇮🇳"
  },
  "hr-HR": {
    "language": "Croatian (Croatia)",
    "voices": {
      "female": "hr-HR-GabrijelaNeural",
      "male": "hr-HR-SreckoNeural"
    },
    "flag": "🇭🇷"
  },
  "hu-HU": {
    "language": "Hungarian (Hungary)",
    "voices": {
      "female": "hu-HU-NoemiNeural",
      "male": "hu-HU-TamasNeural"
    },
    "flag": "🇭🇺"
  },
  "hy-AM": {
    "language": "Armenian (Armenia)",
    "voices": {
      "female": "hy-AM-AnahitNeural",
      "male": "hy-AM-HaykNeural2"
    },
    "flag": "🇦🇲"
  },
  "id-ID": {
    "language": "Indonesian (Indonesia)",
    "voices": {
      "female": "id-ID-GadisNeural",
      "male": "id-ID-ArdiNeural"
    },
    "flag": "🇮🇩"
  },
  "is-IS": {
    "language": "Icelandic (Iceland)",
    "voices": {
      "female": "is-IS-GudrunNeural",
      "male": "is-IS-GunnarNeural2"
    },
    "flag": "🇮🇸"
  },
  "it-IT": {
    "language": "Italian (Italy)",
    "voices": {
      "female": "it-IT-ElsaNeural",
      "male": "it-IT-UbertoNeural"
    },
    "flag": "🇮🇹"
  },
  "ja-JP": {
    "language": "Japanese (Japan)",
    "voices": {
      "female": "ja-JP-NanamiNeural",
      "male": "ja-JP-ShinjiNeural"
    },
    "flag": "🇯🇵"
  },
  "ka-GE": {
    "language": "Georgian (Georgia)",
    "voices": {
      "female": "ka-GE-EkaNeural",
      "male": "ka-GE-GiorgiNeural2"
    },
    "flag": "🇬🇪"
  },
  "kk-KZ": {
    "language": "Kazakh (Kazakhstan)",
    "voices": {
      "female": "kk-KZ-AigulNeural",
      "male": "kk-KZ-DauletNeural2"
    },
    "flag": "🇰🇿"
  },
  "km-KH": {
    "language": "Khmer (Cambodia)",
    "voices": {
      "female": "km-KH-SreymomNeural",
      "male": "km-KH-PisethNeural2"
    },
    "flag": "🇰🇭"
  },
  "kn-IN": {
    "language": "Kannada",
    "voices": {
      "female": "kn-IN-SapnaNeural",
      "male": "kn-IN-GaganNeural2"
    },
    "flag": "🇮🇳"
  },
  "ko-KR": {
    "language": "Korean (Korea)",
    "voices": {
      "female": "ko-KR-SunHiNeural",
      "male": "ko-KR-YuJinNeural"
    },
    "flag": "🇰🇷"
  },
  "lo-LA": {
    "language": "Lao (Laos)",
    "voices": {
      "female": "lo-LA-KeomanyNeural",
      "male": "lo-LA-ChanthavongNeural2"
    },
    "flag": "🇱🇦"
  },
  "lt-LT": {
    "language": "Lithuanian (Lithuania)",
    "voices": {
      "female": "lt-LT-OnaNeural",
      "male": "lt-LT-LeonasNeural2"
    },
    "flag": "🇱🇹"
  },
  "lv-LV": {
    "language": "Latvian (Latvia)",
    "voices": {
      "female": "lv-LV-EveritaNeural",
      "male": "lv-LV-NilsNeural2"
    },
    "flag": "🇱🇻"
  },
  "mk-MK": {
    "language": "Macedonian (North Macedonia)",
    "voices": {
      "female": "mk-MK-MarijaNeural",
      "male": "mk-MK-AleksandarNeural2"
    },
    "flag": "🇲🇰"
  },
  "ml-IN": {
    "language": "Malayalam",
    "voices": {
      "female": "ml-IN-SobhanaNeural",
      "male": "ml-IN-MidhunNeural2"
    },
    "flag": "🇮🇳"
  },
  "ms-MY": {
    "language": "Malay (Malaysia)",
    "voices": {
      "female": "ms-MY-YasminNeural",
      "male": "ms-MY-OsmanNeural"
    },
    "flag": "🇲🇾"
  },
  "mt-MT": {
    "language": "Maltese (Malta)",
    "voices": {
      "female": "mt-MT-GraceNeural",
      "male": "mt-MT-JosephNeural2"
    },
    "flag": "🇲🇹"
  },
  "my-MM": {
    "language": "Burmese (Myanmar)",
    "voices": {
      "female": "my-MM-NilarNeural",
      "male": "my-MM-ThihaNeural2"
    },
    "flag": "🇲🇲"
  },
  "nl-BE": {
    "language": "Dutch (Belgium)",
    "voices": {
      "female": "nl-BE-DenaNeural",
      "male": "nl-BE-ArnaudNeural"
    },
    "flag": "🇧🇪"
  },
  "nl-NL": {
    "language": "Dutch (Netherlands)",
    "voices": {
      "female": "nl-NL-FennaNeural",
      "male": "nl-NL-JanWillemNeural"
    },
    "flag": "🇳🇱"
  },
  "ps-AF": {
    "language": "Pashto (Afghanistan)",
    "voices": {
      "female": "ps-AF-LatifaNeural",
      "male": "ps-AF-ZiaNeural2"
    },
    "flag": "🇦🇫"
  },
  "sq-AL": {
    "language": "Albanian (Albania)",
    "voices": {
      "female": "sq-AL-AnilaNeural",
      "male": "sq-AL-IlirNeural2"
    },
    "flag": "🇦🇱"
  },
  "ur-PK": {
    "language": "Urdu (Pakistan)",
    "voices": {
      "female": "ur-PK-UzmaNeural",
      "male": "ur-PK-AsadNeural"
    },
    "flag": "🇵🇰"
  },
  "wuu-CN": {
    "language": "Chinese (Wu, Simplified)",
    "voices": {
      "female": "wuu-CN-XiaotongNeural",
      "male": "wuu-CN-YunzheNeural2"
    },
    "flag": "🇨🇳"
  },
  "yue-CN": {
    "language": "Chinese (Cantonese, Simplified)",
    "voices": {
      "female": "yue-CN-XiaoMinNeural",
      "male": "yue-CN-YunSongNeural1,2"
    },
    "flag": "🇨🇳"
  },
  "zh-CN": {
    "language": "Chinese (Mandarin, Simplified)",
    "voices": {
      "female": "zh-CN-XiaoyouNeural",
      "male": "zh-CN-YunfengNeural"
    },
    "flag": "🇨🇳"
  },
  "zh-HK": {
    "language": "Chinese (Cantonese, Traditional)",
    "voices": {
      "female": "zh-HK-HiuMaanNeural",
      "male": "zh-HK-WingNeural"
    },
    "flag": "🇭🇰"
  },
}