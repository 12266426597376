import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import './Login.css';

const ContactUs = ({ }) => {

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");

  const awsconfig = {
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_3uYXEhMon',
      userPoolWebClientId: '1g6jutokcivrebiu2tf56fpj11'
    },
    credentials: {
      accessKeyId: 'AKIARUIO25LGPG655F7N',
      secretAccessKey: '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'
    }
  };

  //alert("inside login");

  //Amplify.configure(awsconfig);

  Auth.configure(awsconfig);

  const navigate = useNavigate();

  const sendEmailAddress = "https://hdbvjflnhfp6vvvdditgfgsruq0futml.lambda-url.us-east-2.on.aws/";

  async function sendEmail() {
    const response = await fetch(sendEmailAddress, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        fromEmail: email,
        subject: name,
        message: message
      })
    });
    return response;
  }

  const handleSubmit = async (e) => {

    try {
      console.log("email is " + email);

      const response = await sendEmail().catch((error) => {
        console.error("Error sending email:", error);
        setUserMessage("Error sending email");
      });

      if (response.status === 200) {
        setUserMessage("Thanks for contacting us, we will review your message and get back to you soon");
      }
      else {
        setUserMessage("Error sending message");
      }
    }
    catch (error) {
      setUserMessage("Error sending message");
    }
  }

  return (
    <div className="contact-us" style={{ minHeight: '100vh', width: '100%' }}>
      <div role='form' lang='en-US' dir='ltr'>
        <table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
          <tbody>
            <tr>
              <td colSpan='2' style={{ textAlign: 'center', justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
                <div id="kids_logo_block" >
                  <a id="kids_logo_text" href="index.html"><img src="images/KidstorytaLogo.png" alt="Kidstoryta" title="Kidstoryta Logo" /></a>
                </div>
                <p></p>
                <p style={{ color: 'black' }}>
                  Have questions? Send us a message and we will reach out to you soon
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
                <div style={{ textAlign: 'left', alignItems: 'left', justifyContent: 'left' }}>
                  <input
                    type='text'
                    id='name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder='Name'
                    style={{ width: '100%', height: '40px', fontSize: '16px' }}
                  />
                </div>
              </td>
              <td style={{ justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
                <div style={{ textAlign: 'left', alignItems: 'left', justifyContent: 'left' }}>
                  <input
                    type='text'
                    id='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder='E-mail'
                    style={{ width: '100%', height: '40px', fontSize: '16px' }}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td colSpan='2' style={{ justifyContent: 'left', borderCollapse: 'collapse', borderSpacing: '0', border: 'none' }}>
                <div style={{ textAlign: 'left' }}>
                  <textarea
                    style={{ width: '100%', height: '100%', fontSize: '16px' }}
                    id='message'
                    rows='10'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    maxLength={500}
                    placeholder='Message'
                  ></textarea>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', textAlign: 'center' }}>
          <button onClick={handleSubmit} >Send</button>
        </div>
        <div>{userMessage}</div>
      </div>
    </div>
  );

};

export default ContactUs;