import axios from 'axios';
import jwtDecode from 'jwt-decode';
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Link as RouterLink} from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const OAuth2Callback = () => {

const navigate = useNavigate();
const addUserProfileAddress = "https://wx7dlc2wezs7g47myix66g3k3m0wymxf.lambda-url.us-east-2.on.aws";

const getUserProfileAddress = "https://cidmp4byjeuct4uqk7lvhgbnja0gdhdc.lambda-url.us-east-2.on.aws/";

  async function getUserProfile(email) {
          const response = await fetch(getUserProfileAddress, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify({
                    email: email
                  })
                });
                return response;
      }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const authorizationCode = searchParams.get('code');

    const exchangeAuthorizationCode = async () => {
      try {
        const clientId = '540130539757-4euhj1snjiq8tupvntfbgbv53qucpl02.apps.googleusercontent.com';
        const clientSecret = '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';
        const redirectUri = 'https://app.kidstoryta.ai/oauth2callback'; // Replace with your redirect URI

        const response = await axios.post('https://oauth2.googleapis.com/token', {
          code: authorizationCode,
          client_id: clientId,
          client_secret: clientSecret,
          redirect_uri: redirectUri,
          grant_type: 'authorization_code'
        });

        const { access_token, id_token, refresh_token } = response.data;
        console.log('Access Token:', access_token);
        console.log('ID Token:', id_token);
        console.log('Refresh Token:', refresh_token);

        const userInfo = extractUserInfo(id_token);
        console.log('User Email:', userInfo.email);
        console.log('First Name:', userInfo.firstName);
        console.log('Last Name:', userInfo.lastName);

        const userProfile = await getUserProfile(userInfo.email).catch((error) => {
                                  console.error("Error getting user profile from DB:", error);
                                });

        const userProfileData = await userProfile.json();

        if (userProfileData && userProfileData.email) {
                alert("A user with this email is already registered, please sign in");
                navigate('/login');
        }
        else {
            const profileResponse = await addUserProfile(userInfo.email, userInfo.firstName,userInfo.lastName);

            if (profileResponse !== null && profileResponse.status === 200) {
                  console.log('user profile added to DB');
                  //store email in local storage
                  localStorage.setItem('kidstoryta_email', userInfo.email);
                  navigate('/');
            }
            else {
                console.log("there was an issue with adding the profile");
                //TODO what do we do now? go to register page to display the error?
                //navigate('/Login');
            }
        }

      } catch (error) {
        console.error('Token exchange error:', error);
        // Handle the error
      }
      };

      exchangeAuthorizationCode();
  }, []);

  async function addUserProfile(email, firstName, lastName) {
          var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
          var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

          //const stripe = require('stripe')('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');

          //const customer = await stripe.customers.create({
          //  email: email
          // });

          //console.log("customer created " + customer.id);

          const response = await fetch(addUserProfileAddress, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify({
                    email: email,
                    active: "true",
                    first_name: firstName,
                    last_name: lastName,
                    join_date: localISOTime,
                    last_billing_date: '',
                    plan: '',
                    plan_start_date: '',
                    story_balance: 10,
                    billing_customer_id: 'temp',
                    billing_frequency: '',
                    cancelation_reason: ''
                  })
                });
                return response;
      }

  const extractUserInfo = (idToken) => {
    const decodedToken = jwtDecode(idToken);
    const email = decodedToken.email;
    const firstName = decodedToken.given_name;
    const lastName = decodedToken.family_name;

    return {
      email,
      firstName,
      lastName,
    };
  };

  return null; // or any JSX you want to render
};

export default OAuth2Callback;
